import Debug from "debug";
import * as React from "react";
import { Col as BCol, Form, FormGroup, FormLabel, Row as BRow } from "react-bootstrap";
import { Field, getFieldArray, getFieldColumnWidths, isFieldEditable, Row } from "packages/gossamer-universal";
import { ErrorBoundary, TransField, TransRow } from "../index";
import styles from "./FieldBlock.module.css";

const debug = Debug("gfe/se/FieldSet");

interface TransFieldSetProps<T extends Row> {
  fieldGroup?: string;
  row: TransRow<T>;
}

export const TransFieldSet = <T extends {}>(props: TransFieldSetProps<T>): JSX.Element => {
  if (!props.row) {
    return null; // convenience logic to support useGetRow()
  }
  debug(`${props.row.getRowNumber()}, ${props.fieldGroup}`);
  return (
    <ErrorBoundary>
      <Form>
        <BRow className="mb20px">
          {props.row &&
            getFieldArray(props.row.getEntity())
              .map(([field, fieldId]: [Field, string]) => [
                field,
                fieldId,
                isFieldEditable(field, props.row.getAction()),
              ])
              .filter(([field, fieldId, editable]: [Field, string, boolean]) => {
                const fieldGroupCheck = !props.fieldGroup || props.fieldGroup === field.fieldGroup;
                const isNonBlank = !!props.row.getFieldValue(fieldId);
                const visibleAttrCheck = typeof field.visible === "boolean" ? field.visible : editable || isNonBlank;
                return fieldGroupCheck && visibleAttrCheck;
              })
              .map(([field, fieldId, editable]: [Field, string, boolean]) => (
                <BCol key={fieldId} {...getFieldColumnWidths(field)}>
                  <FormGroup id={fieldId} controlId={fieldId} className={`${styles.FieldBlock} fieldBlock`}>
                    <FormLabel>
                      {field.label}
                      {field.mandatory && editable ? " *" : ""}
                      {field.description && (
                        <>
                          &nbsp;&nbsp;<a title={field.description}>ⓘ</a>
                        </>
                      )}
                    </FormLabel>
                    <TransField fieldId={fieldId} field={field} row={props.row} />
                  </FormGroup>
                </BCol>
              ))}
        </BRow>
      </Form>
    </ErrorBoundary>
  );
};
