import * as React from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { Field, FieldType } from "packages/gossamer-universal";

interface EditableProps {
  className?: string;
  clearOnSelection?: boolean;
  field: Field;
  handleBlur?: (newValue: string) => void;
  handleChange?: (newValue: string) => void;
  validity?: boolean;
  value: string;
}

export const FIELD_TYPE_MAP = {
  [FieldType.Date]: "date",
  [FieldType.Datetime]: "datetime-local",
  [FieldType.Decimal]: "number",
  [FieldType.Integer]: "number",
  [FieldType.Password]: "password",
  [FieldType.Text]: "text",
};

export const Editable = (props: EditableProps): JSX.Element => {
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (props.handleBlur) {
      props.handleBlur(event.target.value.trim());
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.handleChange) {
      props.handleChange(event.target.value.trim());
    }
  };
  const formControlLocal = (
    <FormControl
      className={props.className}
      defaultValue={props.value}
      isInvalid={props.validity === false}
      isValid={props.validity === true}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={props.field.label}
      type={FIELD_TYPE_MAP[props.field.type]}
    />
  );
  if (!props.field.staticPrefix && !props.field.staticSuffix) {
    return formControlLocal;
  }
  return (
    <InputGroup>
      {props.field.staticPrefix && <InputGroup.Text>{props.field.staticPrefix}</InputGroup.Text>}
      {formControlLocal}
      {props.field.staticSuffix && <InputGroup.Text>{props.field.staticSuffix}</InputGroup.Text>}
    </InputGroup>
  );
};
