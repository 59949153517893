import Debug from "debug";
import * as React from "react";
import { Option } from "packages/gossamer-universal";
import { FormControl } from "../index";

const debug = Debug("gfe/fi/ChecksGroup");

interface ChecksGroupProps {
  className?: string;
  clearOnSelection?: boolean; // TODO implement
  inline?: boolean;
  mandatory: boolean;
  onItemSelected: (newValue: string) => void;
  options: Option[];
  type: "radio" | "checkbox" | "switch";
  validity?: boolean;
  value: string;
}

export const ChecksGroup = (props: ChecksGroupProps): JSX.Element => {
  // debug(`${props.type}, ${props.value}`);
  const name = React.useMemo(() => String(Math.random() * 10e14), []);
  const array = props.value.split(",");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debug(event.target);
    const newVal = event.target.value;
    if (props.type === "checkbox") {
      const index = array.indexOf(newVal);
      if (index === -1) {
        array.push(newVal);
      } else {
        array.splice(index, 1);
      }
      props.onItemSelected(array.join(","));
    } else {
      props.onItemSelected(newVal);
    }
  };
  const labelStyle: any = {};
  if (props.inline) {
    labelStyle.display = "inline";
  }
  const options = props.options
    .filter((opt) => opt.active !== false)
    .map((opt) => {
      const checked = props.type === "checkbox" ? array.indexOf(opt.id) > -1 : props.value === opt.id;
      return (
        <div key={opt.id} style={labelStyle}>
          <input
            checked={checked}
            id={`${name}.${opt.id}`}
            name={name}
            onChange={handleChange}
            type={props.type}
            value={opt.id}
          />
          &nbsp;&nbsp;
          <label htmlFor={`${name}.${opt.id}`} style={{ display: "inline" }}>
            {opt.label}
          </label>
        </div>
      );
    });
  return (
    <FormControl className={props.className} flexDirection={props.inline ? "row" : "column"} validity={props.validity}>
      {options}
    </FormControl>
  );
};
