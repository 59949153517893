import * as React from "react";
import {
  Field,
  FieldType,
  OptionsControlType,
  OptionsField,
  ReferenceField,
  getTooltipHoverText,
} from "packages/gossamer-universal";
import { Boolean, ChecksGroup, Dropdown, Editable, File, Flex, Image, Reference, Richtext, Uneditable } from "../index";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface GeneralProps {
  className?: string;
  clearOnSelection?: boolean;
  editable: boolean;
  fieldId: string;
  field: Field;
  handleBlur?: (newValue: string) => void;
  handleChange?: (newValue: string) => void;
  isSearchCriterion?: boolean;
  suppressLink?: boolean;
  validity?: boolean;
  value: string;
  row?: {};
}

export const General = (props: GeneralProps): JSX.Element => {
  let output;
  const mandatory = !!props.isSearchCriterion ? false : !!props.field.mandatory;
  if (props.field.renderEditable && props.editable) {
    output = props.field.renderEditable(props);
  } else if (props.field.renderUneditable && !props.editable) {
    output = props.field.renderUneditable(props);
  } else if (props.field.type === FieldType.Boolean) {
    output = (
      <Boolean
        editable={props.editable}
        field={props.field}
        handleChange={props.handleChange}
        validity={props.validity}
        value={props.value}
      />
    );
  } else if (props.field.type === FieldType.Options && props.editable) {
    if (props.field.excludeValues) {
      (props.field as OptionsField).options.forEach((option) => {
        option.active = props.field.excludeValues.indexOf(option.id) === -1;
      });
    }
    if (!props.field.controlType || props.field.controlType === OptionsControlType.Dropdown) {
      output = (
        <Dropdown
          altTextBlank={props.isSearchCriterion ? "any" : null}
          className={props.className}
          clearOnSelection={props.clearOnSelection}
          isInvalid={props.validity === false}
          isValid={props.validity === true}
          options={props.field.options}
          onItemSelected={props.handleChange}
          mandatory={mandatory}
          value={props.value}
        />
      );
    } else {
      const type =
        [OptionsControlType.ChecksHorizontal, OptionsControlType.ChecksVertical].indexOf(props.field.controlType) > -1
          ? "checkbox"
          : "radio";
      const inline =
        [OptionsControlType.ChecksHorizontal, OptionsControlType.RadioHorizontal].indexOf(props.field.controlType) > -1;
      output = (
        <ChecksGroup
          className={props.className}
          clearOnSelection={props.clearOnSelection}
          inline={inline}
          options={props.field.options}
          onItemSelected={props.handleChange}
          mandatory={mandatory}
          type={type}
          validity={props.validity}
          value={props.value}
        />
      );
    }
  } else if (props.field.type === FieldType.File) {
    output = (
      <File
        editable={props.editable}
        handleChange={props.handleChange}
        mandatory={mandatory}
        validity={props.validity}
        value={props.value}
      />
    );
  } else if (props.field.type === FieldType.Flex) {
    output = <Flex fieldId={props.fieldId} value={props.value} />;
  } else if (props.field.type === FieldType.Image) {
    output = (
      <Image
        editable={props.editable}
        handleChange={props.handleChange}
        mandatory={mandatory}
        validity={props.validity}
        value={props.value}
      />
    );
  } else if (props.field.type === FieldType.Reference) {
    output = (
      <Reference
        className={props.className}
        clearOnSelection={props.clearOnSelection}
        editable={props.editable}
        field={props.field as ReferenceField}
        onItemSelected={props.handleChange}
        suppressLink={props.suppressLink}
        validity={props.validity}
        value={props.value}
      />
    );
  } else if (props.field.type === FieldType.Richtext && props.editable) {
    output = (
      <Richtext
        className={props.className}
        handleChange={props.handleChange}
        mandatory={mandatory}
        validity={props.validity}
        value={props.value}
      />
    );
  } else if (props.editable) {
    output = (
      <Editable
        className={props.className}
        clearOnSelection={props.clearOnSelection}
        field={props.field}
        handleBlur={props.handleBlur}
        handleChange={props.handleChange}
        validity={props.validity}
        value={props.value}
      />
    );
  } else {
    output = <Uneditable className={props.className} field={props.field} value={props.value} />;
  }

  if (props.field.tooltipText) {
    const tooltipText = getTooltipHoverText(props.field, props.row);
    return (
      <OverlayTrigger placement="right" overlay={<Tooltip>{tooltipText}</Tooltip>}>
        {({ ref, ...triggerHandler }) => (
          <div style={{ display: "flex" }}>
            {output}
            <span ref={ref} {...triggerHandler}>
              ⓘ
            </span>
          </div>
        )}
      </OverlayTrigger>
    );
  }
  return output;
};
