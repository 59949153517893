import * as React from "react";
import { CreateWrapper, PageProps, PageTitle, Section, TransButtons, useTrans } from "packages/gossamer-react-redux";
import { Event } from "model";
import { cloneEntity } from "packages/gossamer-universal";

const localEvent = cloneEntity(Event);
// show every field to allow middleware logic to apply - TODO
Object.keys(localEvent.fields).forEach((fieldId) => (localEvent.fields[fieldId].visible = fieldId !== "id"));

export const EventCreate = (props: PageProps): JSX.Element => {
  const { cancel, saveWithOutcome, trans } = useTrans({
    followOnURL: "/event/organising",
  });
  const InnerFrame = props.innerFrame;
  return (
    <InnerFrame>
      <PageTitle verb="Create" entity={Event} />
      <Section>
        <CreateWrapper entity={localEvent} transaction={trans} />
      </Section>
      <TransButtons cancel={cancel} saveWithOutcome={saveWithOutcome} trans={trans} />
    </InnerFrame>
  );
};
