import Debug from "debug";
import { debounce } from "lodash";
import * as React from "react";
import { InputGroup } from "react-bootstrap";
import {
  Field,
  Filter,
  getFieldPrimitiveFromString,
  getFieldStringFromPrimitive,
  getFieldTypeFilterOptions,
  Primitive,
} from "packages/gossamer-universal";
import { Dropdown, General } from "../index";
import styles from "./SearchCriterion.module.css";

const debug = Debug("gfe/se/SearchCriterion");

interface SearchCriterionProps {
  display: "full-edit" | "no-edit" | "value-edit" | "value-equals"; // Omit<FilterDisplay, "hidden">;
  field: Field;
  filter: Filter;
  handleChange: (filter: Filter) => void;
  handleDelete?: () => void;
}

export const SearchCriterion = (props: SearchCriterionProps): JSX.Element => {
  const [filter, setFilter] = React.useState<Filter>(props.filter);
  const options = React.useMemo(() => getFieldTypeFilterOptions(props.field.type), [props.field.type]);

  const handleOperatorChange = (newValue: string) => {
    const newFilter = Object.assign({}, filter, {
      operator: newValue,
    });
    setFilter(newFilter);
    props.handleChange(newFilter);
  };

  const handleValueChange = debounce((newValue: string) => {
    debug(`handleValueChange(${newValue})`);
    const newFilter = Object.assign({}, filter, {
      value: getFieldPrimitiveFromString(props.field.type, newValue),
    });
    setFilter(newFilter);
    props.handleChange(newFilter);
  }, 500);

  return (
    <InputGroup className="mb10px" id={"searchCriterion-" + props.filter.fieldId}>
      {props.display === "full-edit" && (
        <InputGroup.Text>
          <button onClick={props.handleDelete} className="ControlIcon">
            ✖
          </button>
        </InputGroup.Text>
      )}
      <InputGroup.Text>{props.field.label}</InputGroup.Text>
      {props.display === "full-edit" && (
        <Dropdown
          className={styles.SearchOperator}
          options={options}
          mandatory={true}
          value={filter.operator}
          onItemSelected={handleOperatorChange}
        />
      )}
      {(props.display == "no-edit" || props.display == "value-edit") && (
        <InputGroup.Text>{options.find((opt) => opt.id === filter.operator)?.label}</InputGroup.Text>
      )}
      {/* Does NOT support 'in' and 'ni' operators */}
      {props.display !== "no-edit" && filter.operator !== "bl" && filter.operator !== "nb" && (
        <General
          editable={true}
          field={props.field}
          fieldId={filter.fieldId}
          handleChange={handleValueChange}
          isSearchCriterion={true}
          value={getFieldStringFromPrimitive(props.field.type, filter.value as Primitive)}
        />
      )}
      {props.display === "no-edit" && filter.operator !== "bl" && filter.operator !== "nb" && (
        <InputGroup.Text>
          <General
            editable={false}
            field={props.field}
            fieldId={filter.fieldId}
            handleChange={handleValueChange}
            isSearchCriterion={true}
            value={getFieldStringFromPrimitive(props.field.type, filter.value as Primitive)}
          />
        </InputGroup.Text>
      )}
    </InputGroup>
  );
};
