import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormManager, PageProps, PageTitle, Section, useQuery, useRecord } from "packages/gossamer-react-redux";
import { Organisation, OrganisationRow } from "model";
import { getFilter, getListRowLink } from "packages/gossamer-universal";

export const OrganisationUpdate = (props: PageProps): JSX.Element => {
  const params = useParams();
  const record = useRecord(Organisation, params.key);
  const query = useQuery(Organisation, {
    where: {
      and: [getFilter("parentOrganisationId", "eq", params.key)],
    },
  });
  const navigate = useNavigate();
  const onExit = () => navigate(getListRowLink(Organisation.frontEndOptions.listRowLink, params.key));
  const InnerFrame = props.innerFrame;
  return (
    <InnerFrame>
      <PageTitle verb="Update" entity={Organisation} keyValue={params.key} />
      <Section>
        <FormManager
          compoundUrl="/main/~/organisation-update"
          onCancel={onExit}
          onSuccess={onExit}
          parts={[
            { type: "single", entity: Organisation, title: "Organisation", record },
            {
              type: "multi",
              entity: Organisation,
              title: "Child Organisation(s)",
              query,
              alias: "children",
              onAddRow: (data: OrganisationRow) => {
                data.parentOrganisationId = params.key;
              },
            },
          ]}
        />
      </Section>
    </InnerFrame>
  );
};
