import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { Event, Organisation } from "model";
import {
  Display,
  IsAllowed,
  List,
  PageProps,
  PageTitle,
  QueryMulti,
  QuerySingle,
  Section,
} from "packages/gossamer-react-redux";
import { getFilter } from "packages/gossamer-universal";
import { Button } from "react-bootstrap";
import { BasicList } from "packages/gossamer-react-redux/sections/BasicList";
import { InMemoryPaginator } from "packages/gossamer-react-redux/data/InMemoryPaginator";

export const OrganisationDisplay = (props: PageProps): JSX.Element => {
  const params = useParams();
  const InnerFrame = props.innerFrame;

  return (
    <InnerFrame>
      <PageTitle
        verb="Display"
        entity={Organisation}
        keyValue={params.key}
        subtitle="Details relating to an Organisation"
      />

      <Section>
        <QuerySingle entity={Organisation} keyValue={params.key} render={Display} />
        <IsAllowed entity={Organisation} action="update">
          <Link to={`/organisation/update/${params.key}`}>
            <Button>Update</Button>
          </Link>
        </IsAllowed>
      </Section>

      <Section title="Child Organisations">
        <QueryMulti
          entity={Organisation}
          alterEntity={(localEntity: typeof Organisation) => {
            localEntity.fields.parentOrganisationId.listColumn = false;
          }}
          queryOptions={{
            where: {
              and: [getFilter("parentOrganisationId", "eq", params.key)],
            },
          }}
          render={(props) => (
            <InMemoryPaginator
              pageSize={10}
              rows={props.rows}
              render={({ rows }) => <BasicList entity={Organisation} rows={rows} />}
            />
          )}
          showItemCount={false}
          showSettingsControl={false}
          showPaginator="never"
        />
      </Section>

      <Section title="Events">
        <QueryMulti
          entity={Event}
          alterEntity={(localEntity: typeof Event) => {
            localEntity.fields.organisation.listColumn = false;
          }}
          queryOptions={{
            where: {
              and: [getFilter("organisation", "eq", params.key)],
            },
          }}
          render={(props) => (
            <InMemoryPaginator
              pageSize={10}
              rows={props.rows}
              render={({ rows }) => <BasicList entity={Event} rows={rows} />}
            />
          )}
          showItemCount={false}
          showSettingsControl={false}
          showPaginator="never"
        />
      </Section>
    </InnerFrame>
  );
};
