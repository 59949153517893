import Debug from "debug";
import * as React from "react";
import { Entity, Row } from "packages/gossamer-universal";
import { Transaction, TransFieldSet, TransRow, useCreateRow } from "../index";

const debug = Debug("gfe/se/CreateWrapper");

interface Props<T extends Row> {
  data?: Partial<T>;
  entity: Entity<T>;
  fieldGroup?: string;
  linkField?: string;
  linkValue?: string;
  onAddRow?: (row: TransRow<T>) => void;
  transaction: Transaction;
}

export const CreateWrapper = <T extends {}>(props: Props<T>): JSX.Element => {
  const row = useCreateRow(props.transaction, props.entity, props.data);

  React.useEffect(() => {
    if (row && props.linkField && props.linkValue) {
      row.setFieldValue(props.linkField, props.linkValue);
    }
    if (row && props.onAddRow) {
      props.onAddRow(row);
    }
  }, []);
  debug(row);
  return row && <TransFieldSet row={row} fieldGroup={props.fieldGroup} />;
};
