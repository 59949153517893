import * as React from "react";
import { useSelector } from "react-redux";
import { PageProps, PageTitle, QueryMulti, Section, selectUser } from "packages/gossamer-react-redux";
import { Event, EventRow, Reservation, ReservationRow } from "model";
import { EventTile } from "./EventTile";
import { EventTileContainer } from "./EventTileContainer";
import { getFilter, getISODateString } from "packages/gossamer-universal";

export const EventsFavourited = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  const user = useSelector(selectUser)();
  return (
    <InnerFrame>
      <PageTitle title="Events I have Favourited" />
      <Section>
        <QueryMulti
          entity={Reservation}
          queryOptions={{
            where: {
              and: [getFilter("userId", "eq", user.userId), getFilter("visibility", "ne", "HIDDEN")],
            },
          }}
          showItemCount={false}
          showPaginator="never"
          showSettingsControl={false}
          render={(outerProps) => (
            <QueryMulti
              entity={Event}
              queryOptions={{
                where: {
                  // events from six months ago onwards
                  and: [getFilter("startsAt", "gt", getISODateString(new Date(Date.now() - 182 * 24 * 3600 * 1000)))],
                },
              }}
              showItemCount={false}
              showPaginator="never"
              showSettingsControl={false}
              render={(middleProps) => {
                const children = middleProps.rows
                  .map((eventRow) => [
                    eventRow,
                    outerProps.rows.find((res: ReservationRow) => res.eventId === eventRow.id),
                  ])
                  .filter(([_, reservationRow]) => (reservationRow as ReservationRow)?.isFavourite)
                  .map(([eventRow, reservationRow]: [EventRow, ReservationRow]) => (
                    <EventTile eventRow={eventRow} key={eventRow.id} existingReservationRow={reservationRow} />
                  ));
                return <EventTileContainer>{children}</EventTileContainer>;
              }}
            />
          )}
        />
      </Section>
    </InnerFrame>
  );
};
