import Debug from "debug";
import { AuthProvider } from "packages/gossamer-universal";
import { getConfig } from "..";

const debug = Debug("gfe/au/AuthProvider");

let provider: AuthProvider = null;

export const getAuthProvider = () => {
  if (!provider) {
    throw new Error(`AuthProvider has not been set`);
  }
  return provider;
};

export const setAuthProvider = (arg: AuthProvider) => {
  if (provider) {
    throw new Error(`AuthProvider already set`);
  }
  provider = arg;
};

export const makeDemoAuthProvider = (): AuthProvider => {
  const onload = async () => {
    return getConfig("user-id");
  };

  const passwordResetInitiate = async () => {
    debug(`doing password reset initiate`);
  };

  const passwordResetComplete = async () => {
    debug(`doing password reset complete`);
  };

  const passwordResetResendCode = async () => {
    debug(`doing password reset resend code`);
  };

  const signIn = async () => {
    debug(`doing sign in`);
  };

  const signInMFACode = async () => {
    debug(`doing sign in mfa code`);
  };

  const signOut = async () => {
    window.localStorage.removeItem("user-id");
    debug(`doing sign out`);
  };

  const signUp = async () => {
    debug(`doing sign up`);
  };

  const signUpResendCode = async () => {
    debug(`doing sign up resend code`);
  };

  const signUpVerifyCode = async () => {
    debug(`signUpVerifyCode`);
  };

  const signUpVerifyMFACode = async () => {
    debug(`signUpVerifyMFACode`);
  };

  return {
    onload,
    passwordResetInitiate,
    passwordResetComplete,
    passwordResetResendCode,
    signIn,
    signInMFACode,
    signOut,
    signUp,
    signUpResendCode,
    signUpVerifyCode,
    signUpVerifyMFACode,
  };
};
