import { Primitive } from "../types/core";
import { Field, FieldType, OptionsField, TextField } from "../types/entity";

// \u0009 tab
// \u000A LF
// \u0020-\u007E basic latin letters, digits and symbols excludes control chars
// \u00A0-\u00FF latin-1 supplement punctuation, symbols and characters
// export const GENERAL_CHAR_REGEX = /^[\u0009\u000A\u0020-\u007E\u00A0-\u00FF]*$/;
//
export const GENERAL_CHAR_REGEX = /^[\s\P{Cc}]*$/u;

export const validate = (field: Field, value: Primitive | Date): string | null => {
  const isNullish = value === undefined || value === null;
  if (field.mandatory && (isNullish || String(value).trim() === "")) {
    return "required";
  }
  if (isNullish) {
    return null; // nullish always allowed if field is not mandatory
  }

  // Boolean Checks
  if (field.type === FieldType.Boolean) {
    if (typeof value !== "boolean") {
      return "should be a boolean value but isn't";
    }
    return null;
  }

  // Number checks
  if ([FieldType.Decimal, FieldType.Integer].indexOf(field.type) > -1) {
    if (typeof value !== "number") {
      return "should be a number value but isn't";
    }
    if (Number.isNaN(value) || !Number.isFinite(value)) {
      return "should be a finite number but isn't";
    }
    if (field.type === FieldType.Integer && !Number.isInteger(value)) {
      return "should be an integer but isn't";
    }
    if (typeof field.min === "number" && value < field.min) {
      return `must be at least ${field.min}`;
    }
    if (typeof field.max === "number" && value > field.max) {
      return `must be at most ${field.max}`;
    }
    return null;
  }

  if ([FieldType.Date, FieldType.Datetime].indexOf(field.type) > -1) {
    const d = value instanceof Date ? value : new Date(value as string);
    try {
      d.toISOString(); // throws if date is invalid
      return null;
    } catch (e) {
      return `invalid date`;
    }
  }

  if (typeof value !== "string") {
    return "should be a string value but isn't";
  }

  if (!GENERAL_CHAR_REGEX.test(value)) {
    return "invalid character(s)";
  }

  if (field.encrypted) {
    return null; // TODO - can't validate encrypted values
  }

  if (typeof field.regex === "string") {
    const regexp = new RegExp(field.regex);
    if (!regexp.test(value)) {
      return field.regexFailText || `must match pattern ${field.regex}`;
    }
  }

  const minLength = (field as TextField).minLength;
  if (typeof minLength === "number" && value.length < minLength) {
    return `must be at least ${minLength} characters long`;
  }

  const maxLength = (field as TextField).maxLength;
  if (typeof maxLength === "number" && value.length > maxLength) {
    return `must be at most ${maxLength} characters long`;
  }

  const options = (field as OptionsField).options;
  if (options && !options.find((option) => option.id === String(value))) {
    return `not one of the allowed options`;
  }

  return null;
};
