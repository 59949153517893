import * as React from "react";
// import { useSelector } from "react-redux";
import { PageProps, selectUser, useQuery } from "packages/gossamer-react-redux";
import { Organisation } from "model";

export const Test = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  //   const user = useSelector(selectUser)();
  const organisationResp = useQuery(Organisation, {});

  return (
    <InnerFrame>
      <div>Foo bar</div>
      <pre>{JSON.stringify(organisationResp, null, 2)}</pre>
    </InnerFrame>
  );
};
