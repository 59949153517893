import * as React from "react";
import { useParams } from "react-router-dom";
import {
  PageProps,
  PageTitle,
  Section,
  selectIsAdmin,
  TransButtons,
  UpdateWrapper,
  useTrans,
} from "packages/gossamer-react-redux";
import { Event } from "model";
import { cloneEntity, getListRowLink, PersistenceType } from "packages/gossamer-universal";
import { useSelector } from "react-redux";

const localEvent = cloneEntity(Event);

export const EventUpdate = (props: PageProps): JSX.Element => {
  const params = useParams();
  const { cancel, saveWithOutcome, trans } = useTrans({
    followOnURL: getListRowLink(Event.frontEndOptions.listRowLink, params.key),
  });
  const InnerFrame = props.innerFrame;
  const isAdmin = useSelector(selectIsAdmin)();
  if (isAdmin) {
    localEvent.fields.organiser.persistence = PersistenceType.Normal;
    localEvent.fields.organisation.persistence = PersistenceType.Normal;
  }

  return (
    <InnerFrame>
      <PageTitle verb="Update" entity={Event} keyValue={params.key} />
      <Section>
        <UpdateWrapper entity={localEvent} keyValue={params.key} transaction={trans} />
      </Section>
      <TransButtons cancel={cancel} saveWithOutcome={saveWithOutcome} trans={trans} />
    </InnerFrame>
  );
};
