import Debug from "debug";
import * as React from "react";
import { Alert, Button, Form, FormControl, FormGroup, FormLabel, Modal, Spinner, Stack } from "react-bootstrap";
import { useNavigate } from "react-router";
import { getAuthProvider } from "../index";

const debug = Debug("gfe/au/SignUp");

export const SignUp = (): JSX.Element => {
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>(null);
  const nameRef = React.useRef<HTMLInputElement>();
  const emailAdrRef = React.useRef<HTMLInputElement>();
  const emailAdrRef2 = React.useRef<HTMLInputElement>();
  const passwordRef = React.useRef<HTMLInputElement>();
  const navigate = useNavigate();

  const handleFormSubmit = (event: React.FormEvent) => {
    signup();
    event.preventDefault();
  };

  const signup = async () => {
    try {
      if (!nameRef.current.value) {
        setMessage(`please enter your name`);
        return;
      }
      const nameParts = nameRef.current.value.split(" ");
      if (nameParts.length < 2 || !nameParts[0] || !nameParts[1]) {
        setMessage(`please enter your first and last name, separated by a space`);
        return;
      }
      if (!emailAdrRef.current.value || emailAdrRef.current.value.indexOf("@") === -1) {
        setMessage(`please enter your email address`);
        return;
      }
      if (emailAdrRef.current.value !== emailAdrRef2.current.value) {
        setMessage(`you have not re-typed your email address exactly the same`);
        return;
      }
      if (!passwordRef.current.value) {
        setMessage(`please enter a password`);
        return;
      }
      setMessage(null);
      setLoading(true);
      debug(`calling signUp(${emailAdrRef.current.value}, ${passwordRef.current.value})`);
      await getAuthProvider().signUp(emailAdrRef.current.value, passwordRef.current.value, nameRef.current.value);
      window.location.href = "/"; // works whereas navigate("/") doesn't for reasons unknown...
    } catch (err: any) {
      console.error(err);
      setMessage(err.message);
      setLoading(false);
    }
  };

  return (
    <Modal show={true} backdrop="static" centered={true} size="lg">
      <Form onSubmit={handleFormSubmit}>
        <Modal.Header>
          <Modal.Title>Signup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message && <Alert variant="danger">{message}</Alert>}
          <FormGroup controlId="name">
            <FormLabel>Name</FormLabel>
            <FormControl type="text" ref={nameRef} />
          </FormGroup>
          <br />
          <FormGroup controlId="username">
            <FormLabel>Email address</FormLabel>
            <FormControl type="text" ref={emailAdrRef} autoComplete="username" />
          </FormGroup>
          <br />
          <FormGroup controlId="username2">
            <FormLabel>Retype email address</FormLabel>
            <FormControl type="text" ref={emailAdrRef2} autoComplete="username" />
          </FormGroup>
          <br />
          <FormGroup controlId="password">
            <FormLabel>Password</FormLabel>
            <FormControl type="password" ref={passwordRef} autoComplete="new-password" />
          </FormGroup>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Stack
            direction="horizontal"
            style={{
              justifyContent: "flex-end",
            }}
          >
            <Button variant="link" href="/signin">
              Log in
            </Button>
            <Button variant="primary" type="submit">
              {isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              {!isLoading && "Sign up"}
            </Button>
          </Stack>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
