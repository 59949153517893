import Debug from "debug";
import {
  getAuthProvider,
  getDataProvider,
  handleSignIn,
  handleSignOut,
  setConfig,
} from "packages/gossamer-react-redux";
import { User } from "../../model/User";

const debug = Debug("model/Auth");

const ADMINS = ["stephen.d.francis@gmail.com", "jameswallner@ymail.com"];

export const getRoleFromUserId = (userId: string): string => (ADMINS.indexOf(userId) === -1 ? "normal" : "admin");

export const signIn = async (userId: string, password: string) => {
  debug(`calling signIn(${userId}, xxx)`);
  await getAuthProvider().signIn(userId, password);
  await onSuccessfulSignIn(userId);
};

export const onAlreadySignedIn = async (email: string) => {
  await onSuccessfulSignIn(email);
};

export const onSuccessfulSignIn = async (email: string) => {
  setConfig("user-id", email);
  setConfig("role-id", "normal");
  const userRow = await getDataProvider().getRecord(User, email);
  const roleId = getRoleFromUserId(email);
  debug(`onAlreadySignedIn`, email, userRow.firstName, userRow.lastName, userRow.organisation);
  handleSignIn(email, roleId, userRow.organisation, [userRow.firstName, userRow.lastName].join(" "), email);
};

export const onSignOut = async () => {
  handleSignOut();
};
