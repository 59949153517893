import Debug from "debug";
import * as React from "react";
import { Button, FormGroup, InputGroup, Table } from "react-bootstrap";
import {
  cloneField,
  DataRowAction,
  Entity,
  Field,
  getFieldArray,
  getFieldColumnAlign,
  isAllowAddRows,
  isAllowDeleteRows,
  isFieldEditable,
  Row,
} from "packages/gossamer-universal";
import { ErrorBoundary, FinalFieldGeneral, General, getInitialValuesNewRow } from "../index";
import { useForm, useFormState } from "react-final-form";

const debug = Debug("gfe/se/FinalGrid");

export interface FinalGridProps<T extends Row> {
  addRowFieldId?: string;
  alias?: string;
  columns?: [Field, string][];
  entity: Entity<T>;
  onAddRow?: (newData: T) => void;
  renderFooter?: () => JSX.Element;
  renderHeader?: (props: FinalGridHeaderProps<T>) => JSX.Element;
  renderRow?: (props: FinalGridRowProps<T>) => JSX.Element;
}

export const FinalGrid = <T extends {}>(props: FinalGridProps<T>): JSX.Element => {
  debug("start of render Grid");
  const columns =
    props.columns || getFieldArray(props.entity).filter(([field, _]: [Field, string]) => field.listColumn);
  const {
    mutators: { push, setRowDelete },
  } = useForm();
  const { values } = useFormState();
  console.log("FinalGrid values", values);
  const identifier = props.alias || props.entity.id;
  const rowData = values[identifier] as T[];
  const LocalGridFooter = props.renderFooter || (() => null);
  const LocalGridHeader = props.renderHeader || FinalGridHeader;
  const LocalGridRow = props.renderRow || FinalGridRow;

  let addRowField = null;
  if (isAllowAddRows(props.entity) && typeof props.addRowFieldId === "string") {
    addRowField = cloneField(props.entity.fields[props.addRowFieldId]);
    addRowField.mandatory = false;
    addRowField.editable = true;
    addRowField.label = "Add a new " + addRowField.label;
    props.entity.fields[props.addRowFieldId].editable = false;
  }

  const allowRowAdd = isAllowAddRows(props.entity);
  const addRow = () => {
    const data = getInitialValuesNewRow(props.entity);
    if (props.onAddRow) {
      props.onAddRow(data);
    }
    push(identifier, data);
  };
  const allowRowDelete = isAllowDeleteRows(props.entity);
  const deleteRow = (index: number) => {
    if (index < 0 || index >= rowData.length) {
      throw new Error(`index ${index} is out of range 0 - ${rowData.length - 1}`);
    }
    debug("calling grid.deleteRow()", identifier, index);
    try {
      setRowDelete(identifier, index);
    } catch (e) {
      console.error(e);
    }
    // mutate row identifier, index set _action = "D"
  };
  const children = rowData.map((row: T, index: number) => {
    return (
      <LocalGridRow
        key={String(index)}
        action={row["_action"]}
        allowRowDelete={allowRowDelete}
        columns={columns}
        deleteRow={deleteRow}
        identifier={identifier}
        index={index}
      />
    );
  });
  return (
    <ErrorBoundary>
      <Table responsive striped bordered>
        <LocalGridHeader allowRowDelete={allowRowDelete} columns={columns} />
        <tbody>{children}</tbody>
        <LocalGridFooter />
      </Table>
      <FinalGridRowAdder allowRowAdd={allowRowAdd} addRowField={addRowField} addRow={addRow} />
    </ErrorBoundary>
  );
};

export interface FinalGridHeaderProps<T extends Row> {
  allowRowDelete: boolean;
  columns: [Field, string][];
}

export const FinalGridHeader = <T extends {}>(props: FinalGridHeaderProps<T>): JSX.Element => {
  return (
    <thead>
      <tr>
        {props.allowRowDelete && <th>&nbsp;</th>}
        {props.columns.map(([field, fieldId]: [Field, string]) => (
          <th key={fieldId} className={`cellAlign-${getFieldColumnAlign(field)}`}>
            {field.label}
            {field.mandatory ? " *" : ""}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export interface FinalGridRowProps<T extends Row> {
  action: DataRowAction;
  allowRowDelete: boolean;
  columns: [Field, string][];
  deleteRow: (index: number) => void;
  identifier: string;
  index: number;
}

export const FinalGridRow = <T extends {}>(props: FinalGridRowProps<T>): JSX.Element => {
  if (props.action === "D") {
    return null;
  }
  return (
    <tr>
      {props.allowRowDelete && (
        <td key="~deletion">
          <button type="button" onClick={() => props.deleteRow(props.index)} className="ControlIcon">
            ✖
          </button>
        </td>
      )}
      {props.columns.map(([field, fieldId]: [Field, string]) => (
        <td key={fieldId} className={`cellAlign-${getFieldColumnAlign(field)}`}>
          <FormGroup controlId={fieldId}>
            <FinalFieldGeneral
              fullId={`${props.identifier}[${props.index}].${fieldId}`}
              field={field}
              editable={isFieldEditable(field, props.action)}
            />
          </FormGroup>
        </td>
      ))}
    </tr>
  );
};

export interface FinalGridRowAdderProps {
  addRowField?: Field;
  allowRowAdd: boolean;
  addRow: () => void;
}

export const FinalGridRowAdder = (props: FinalGridRowAdderProps) => {
  return (
    <div className="mb20px">
      {props.allowRowAdd && !props.addRowField && (
        <div>
          <Button
            onClick={() => {
              props.addRow();
            }}
            className="main"
          >
            Add New Row
          </Button>
        </div>
      )}
      {!!props.addRowField && (
        <InputGroup size="sm">
          <InputGroup.Text>{props.addRowField.label}</InputGroup.Text>
          <General
            className="addNewRow"
            clearOnSelection={true}
            editable={true}
            fieldId="addNewRow"
            field={props.addRowField}
            handleChange={props.addRow}
            value=""
          />
        </InputGroup>
      )}
    </div>
  );
};
