import Debug from "debug";
import * as React from "react";
import { FormSelect } from "react-bootstrap";
import { Option } from "packages/gossamer-universal";

const debug = Debug("gfe/fi/Dropdown");

interface OptionsProps {
  altTextBlank?: string;
  altTextChoose?: string;
  mandatory: boolean;
  options: Option[];
  value: string;
}

const getOptions = (props: OptionsProps) => {
  const options = props.options
    .filter((opt) => opt.active !== false)
    .map((opt) => (
      <option key={opt.id} value={String(opt.id)}>
        {opt.label}
      </option>
    ));
  if (!props.mandatory) {
    options.unshift(
      <option key="*" value="*">
        [{props.altTextBlank || "blank"}]
      </option>
    );
  } else if (!props.value) {
    options.unshift(
      <option key="*" value="*">
        [{props.altTextChoose || "choose"}]
      </option>
    );
  }
  return options;
};

interface DropdownRawProps extends OptionsProps {
  className?: string;
  isInvalid?: boolean;
  isValid?: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const DropdownRaw = (props: DropdownRawProps) => (
  <FormSelect
    className={props.className}
    isInvalid={props.isInvalid}
    isValid={props.isValid}
    onChange={props.onChange}
    defaultValue={props.value}
  >
    {getOptions(props)}
  </FormSelect>
);

interface DropdownProps extends OptionsProps {
  className?: string;
  clearOnSelection?: boolean;
  isInvalid?: boolean;
  isValid?: boolean;
  onItemSelected: (newValue: string) => void;
}

export const Dropdown = (props: DropdownProps): JSX.Element => {
  const [value, setValue] = React.useState<string>(String(props.value) || "");
  debug(
    `Dropdown ${props.value}, ${props.options.length} matches? ${JSON.stringify(
      props.options.find((opt) => String(opt.id) === value)
    )}`
  );

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newVal = event.target.value === "*" ? "" : event.target.value;
    props.onItemSelected(newVal || null); // convert "" to null
    if (props.clearOnSelection) {
      setValue("");
    } else {
      setValue(newVal);
    }
  };
  return (
    <FormSelect
      className={props.className}
      isInvalid={props.isInvalid}
      isValid={props.isValid}
      onChange={handleChange}
      value={value}
    >
      {getOptions(props)}
    </FormSelect>
  );
};
