import Debug from "debug";
import { Dispatch, Middleware } from "redux";
import { displayReduxTKError } from "../index";
import {
  addQueryToGCache,
  addRecordToGCache,
  GCacheState,
  cacheSweep,
  clearSingleQueryInGCache,
  clearSingleRecordInGCache,
  getQueryFromGCache,
  GetQueryPayload,
  getRecordFromGCache,
  GetRecordPayload,
  makeQueryGCacheKey,
  makeRecordGCacheKey,
  setQueryDataInGCache,
  setQueryErrorInGCache,
  setRecordDataInGCache,
  setRecordErrorInGCache,
} from "./ReduxGCache";
import { DataProvider, DataVolume, Entity, getRowKey, Row } from "packages/gossamer-universal";

const debug = Debug("gfe/da/ReduxGCacheMiddleware");

const shouldRetrieveAllDataInSingleQuery = (entity: Entity<Row>) => entity.dataVolume === DataVolume.Small;

const getTimeToLiveRecordMillis = (entity: Entity<Row>) => 30 * 14 * 1000;

const getTimeToLiveQueryMillis = (entity: Entity<Row>) => 5 * 14 * 1000;

// Middleware
export const gCacheMiddleware: (sourceDataProvider: DataProvider) => Middleware<{}, { cache: GCacheState }> =
  (sourceDataProvider) => (store) => (next) => async (action) => {
    // debug(`inside cacheMiddleware action: ${action.type}, ${getQueryFromGCache.type}`);
    if (action.type === getRecordFromGCache.type) {
      // no reducer for this action
      onGetRecordFromGCache(sourceDataProvider, store.getState().cache, store.dispatch, action.payload);
    } else if (action.type === getQueryFromGCache.type) {
      // no reducer for this action
      onGetQueryFromGCache(sourceDataProvider, store.getState().cache, store.dispatch, action.payload);
    } else if (action.type === cacheSweep.type) {
      // no reducer for this action
      doGCacheSweep(store.getState().cache, store.dispatch);
    } else {
      next(action);
    }
  };

const onGetRecordFromGCache = (
  sourceDataProvider: DataProvider,
  cache: GCacheState,
  dispatch: Dispatch,
  payload: GetRecordPayload
) => {
  const now = Date.now();
  const item = cache.records[makeRecordGCacheKey(payload.entity.id, payload.key)];
  const cacheHit = !!item && item.expiresAt > now;
  // debug(`getting record ${payload.entity.id}/${payload.key} from cache: ${cacheHit}, ${item?.expiresAt} >? ${now}`);
  if (!cacheHit) {
    onRecordGCacheMiss(sourceDataProvider, cache, dispatch, payload);
  }
};

const onRecordGCacheMiss = (
  sourceDataProvider: DataProvider,
  cache: GCacheState,
  dispatch: Dispatch,
  payload: GetRecordPayload
) => {
  if (shouldRetrieveAllDataInSingleQuery(payload.entity)) {
    onGetQueryFromGCache(sourceDataProvider, cache, dispatch, {
      entity: payload.entity,
      query: {},
    });
    return;
  }
  dispatch(
    addRecordToGCache({
      entityId: payload.entity.id,
      key: payload.key,
    })
  );
  // debug(`calling sourceDataProvider.getRecord()...`);
  sourceDataProvider
    .getRecord(payload.entity, payload.key)
    .then((data) => {
      dispatch(
        setRecordDataInGCache({
          entityId: payload.entity.id,
          key: payload.key,
          data,
          expiresAt: Date.now() + getTimeToLiveRecordMillis(payload.entity),
        })
      );
    })
    .catch((err) => {
      console.error(err);
      dispatch(
        setRecordErrorInGCache({
          entityId: payload.entity.id,
          key: payload.key,
          error: displayReduxTKError(err),
        })
      );
    });
};

const onGetQueryFromGCache = (
  sourceDataProvider: DataProvider,
  cache: GCacheState,
  dispatch: Dispatch,
  payload: GetQueryPayload
) => {
  // debug("inside onGetQueryFromGCache...");
  const now = Date.now();
  const queryStr = JSON.stringify(payload.query);
  const item = cache.queries[makeQueryGCacheKey(payload.entity.id, queryStr)];
  const cacheHit = !!item && item.expiresAt > now;
  // debug(`getting query ${payload.entity.id}/{...} from cache: ${cacheHit}, ${item?.expiresAt} >? ${now}`);
  // if (cacheHit) {
  //   debug(`data already exists: ${cache.queries[makeQueryGCacheKey(payload.entity.id, queryStr)]?.data?.length}`);
  // }
  if (!cacheHit) {
    onQueryGCacheMiss(sourceDataProvider, dispatch, payload, queryStr);
  }
};

const onQueryGCacheMiss = (
  sourceDataProvider: DataProvider,
  dispatch: Dispatch,
  payload: GetQueryPayload,
  queryStr: string
) => {
  dispatch(
    addQueryToGCache({
      entityId: payload.entity.id,
      query: queryStr,
    })
  );
  // debug(`calling fetchApi...`);
  sourceDataProvider
    .getQuery(payload.entity, payload.query)
    .then((data) => {
      // debug(`return from fetchApi... with data: ${data?.length}`);
      dispatch(
        setQueryDataInGCache({
          entityId: payload.entity.id,
          query: queryStr,
          data: data,
          keys: data.map((row) => getRowKey(payload.entity, row)),
          expiresAt: Date.now() + getTimeToLiveQueryMillis(payload.entity),
        })
      );
    })
    .catch((err) => {
      console.error(err);
      dispatch(
        setQueryErrorInGCache({
          entityId: payload.entity.id,
          query: queryStr,
          error: displayReduxTKError(err),
        })
      );
    });
};

/**
 * This approach will remove cached items even if still in use in the page. This
 * causes an "infinite spinner" as nothing triggers a reload of these items.
 */
const doGCacheSweep = (cache: GCacheState, next: Dispatch) => {
  debug(`starting cache sweep... queries: ${Object.values(cache.queries).length}`);
  let count = 0;
  const now = Date.now();
  Object.values(cache.queries).forEach((item) => {
    debug(`sweep: checking query ${item.entityId}: ${item.expiresAt} < ${now} ? ${item.expiresAt < now}`);
    if (item.expiresAt < now) {
      debug(`yes! removing...`);
      count += 1;
      next(
        clearSingleQueryInGCache({
          entityId: item.entityId,
          query: item.query,
        })
      );
    }
  });
  Object.values(cache.records).forEach((item) => {
    debug(`sweep: checking record ${item.entityId}/${item.key}: ${item.expiresAt} < ${now} ? ${item.expiresAt < now}`);
    if (item.expiresAt < now) {
      count += 1;
      next(
        clearSingleRecordInGCache({
          entityId: item.entityId,
          key: item.key,
        })
      );
    }
  });
  debug(`finishing cache sweep... ${count}`);
};
