import * as React from "react";
import { Entity, Row } from "packages/gossamer-universal";
import { Transaction, TransRow } from "../index";

export const useCreateRow = <T extends Row>(trans: Transaction, entity: Entity<T>, data?: Partial<T>) => {
  const [row, setRow] = React.useState<TransRow<T>>(null);
  React.useEffect(() => {
    if (!trans) {
      return; // waiting for transaction to be created
    }
    if (!!row) {
      throw new Error(`trans or entity changed after row created`);
    }
    console.log(`useCreateRow useEffect`, trans.getTransId());
    setRow(trans.makeCreateRow(entity, data));
  }, [trans, entity]);
  return row;
};
