import {
  CompoundPayload,
  createData,
  DataRowAction,
  Entity,
  getRowKey,
  getUuid,
  PersistenceType,
  QueryResponse,
  RecordResponse,
  Row,
  RowEnvelope,
} from "packages/gossamer-universal";
import React from "react";
import { getDataProvider } from "./DataProvider";
import { Form } from "react-final-form";
import { ValidationErrors } from "final-form";
import arrayMutators from "final-form-arrays";
import { clearAllQueriesInGCache, clearSingleRecordInGCache } from "./ReduxGCache";
import { ParallelLoad } from "./ParallelLoad";
import { Dispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { Alert } from "react-bootstrap";
import { Section } from "../sections/Section";
import { FinalGrid } from "../sections/FinalGrid";
import { FinalForm } from "../sections/FinalForm";
import { FinalButtons } from "../sections/FinalButtons";

type PartType = "single" | "multi";

interface FormPartBase<T extends Row> {
  alias?: string;
  entity: Entity<T>;
  fieldGroup?: string;
  type: PartType;
  title?: string;
  subtitle?: string;
}

interface FormPartSingle<T extends Row> extends FormPartBase<T> {
  record?: RecordResponse<T>;
  type: "single";
}

interface FormPartMulti<T extends Row> extends FormPartBase<T> {
  query?: QueryResponse<T>;
  type: "multi";
  onAddRow?: (data: T) => void;
}

type FormPart<T extends Row> = FormPartSingle<T> | FormPartMulti<T>;

export const makeCompoundPayload = () => ({
  transId: getUuid(),
  rows: [],
});

const getIdentifier = (part: FormPart<Row>) => part.alias || part.entity.id;

const validateParts = (parts: FormPart<Row>[]) => {
  if (parts.length === 0) {
    throw new Error(`no parts configured`);
  }
  const identifiers = [];
  parts.forEach((part) => {
    const identifier = getIdentifier(part);
    if (identifiers.indexOf(identifier) > -1) {
      throw new Error(`duplicate part identifier: ${identifier}`);
    }
    identifiers.push(identifier);
  });
};

const isMultiRowPart = <T extends Row>(part: FormPart<T>) => part.type === "multi";

const getPartInitialValues = <T extends Row>(part: FormPart<T>) =>
  isMultiRowPart(part)
    ? getInitialValuesMulti(part as FormPartMulti<T>)
    : getInitialValuesSingle(part as FormPartSingle<T>);

const getInitialValuesExistingRow = <T extends Row>(entity: Entity<T>, data: T) => {
  return Object.assign(
    {
      _action: "U",
      _original_key: getRowKey(entity, data),
    },
    data
  );
};

export const getInitialValuesNewRow = <T extends Row>(entity: Entity<T>) => {
  const data = createData(entity);
  data["_action"] = "C";
  return data;
};

const getInitialValuesSingle = (part: FormPartSingle<Row>) =>
  part.record ? getInitialValuesExistingRow(part.entity, part.record.data) : getInitialValuesNewRow(part.entity);

const getInitialValuesMulti = (part: FormPartMulti<Row>) =>
  part.query?.data.map((row) => getInitialValuesExistingRow(part.entity, row)) || [];

const getAllInitialValues = (parts: FormPart<Row>[]) => {
  const initialValues = {};
  parts.forEach((part) => {
    initialValues[getIdentifier(part)] = getPartInitialValues(part);
  });
  return initialValues;
};

// Field Persistence Types included on Create
const USED_ON_CREATE = [PersistenceType.Normal, PersistenceType.FrontEndGenerated, PersistenceType.SetOnCreateOnly];
const USED_ON_UPDATE = [PersistenceType.Normal, PersistenceType.FrontEndGenerated];

export const addRowsToPayload = (payload: CompoundPayload, parts: FormPart<Row>[], values: any) => {
  parts.forEach((part) => {
    const identifier = getIdentifier(part);
    if (isMultiRowPart(part)) {
      (values[identifier] as Row[]).forEach((row) => {
        addRowToPayload(payload, part.entity, row);
      });
    } else {
      addRowToPayload(payload, part.entity, values[identifier]);
    }
  });
};

export const addRowToPayload = <T extends Row>(payload: CompoundPayload, entity: Entity<T>, values: any) => {
  const data = {};
  const action: DataRowAction = values._action;
  Object.keys(entity.fields)
    .filter(
      (fieldId) =>
        (action === "C" ? USED_ON_CREATE : USED_ON_UPDATE).indexOf(
          entity.fields[fieldId].persistenceType || PersistenceType.Normal
        ) > -1
    )
    .forEach((fieldId) => {
      if (values[fieldId] !== undefined) {
        data[fieldId] = values[fieldId];
      }
    });
  const key = getRowKey(entity, data);
  if (action === "U" && key !== values._original_key) {
    throw new Error(`key change in row update`);
  }
  const row: RowEnvelope<T> = {
    entityId: entity.id,
    action,
    data: data as T,
    key,
  };
  payload.rows.push(row);
};

export const resetCacheAll = (dispatch: Dispatch, parts: FormPart<Row>[], values: any) => {
  parts.forEach((part) => {
    const identifier = getIdentifier(part);
    if (isMultiRowPart(part)) {
      (values[identifier] as Row[]).forEach((row) => {
        resetCachePart(dispatch, part.entity.id);
      });
    } else {
      resetCachePart(dispatch, part.entity.id, values[identifier]._original_key);
    }
  });
};

export const resetCachePart = (dispatch: Dispatch, entityId: string, updateKey?: string) => {
  dispatch(
    clearAllQueriesInGCache({
      entityId,
    })
  );
  if (updateKey) {
    dispatch(
      clearSingleRecordInGCache({
        entityId,
        key: updateKey,
      })
    );
  }
};

interface FormManagertRenderProps {
  form: {
    mutators: Record<string, (...args: any[]) => any>;
  }; // injected from final-form-arrays above
  handleSubmit: () => void;
  onCancel?: () => void;
  parts: FormPart<{}>[];
  submitError: any;
  submitting: boolean;
}

interface FormManagerProps {
  compoundUrl: string;
  onCancel?: () => void;
  onSuccess: () => void;
  parts: FormPart<{}>[];
  render?: (props: FormManagertRenderProps) => JSX.Element;
  validate?: (values: any) => ValidationErrors | Promise<ValidationErrors>;
}

export const FormManager = (props: FormManagerProps): JSX.Element => {
  validateParts(props.parts);
  return (
    <ParallelLoad
      responses={props.parts
        .map((part) =>
          isMultiRowPart(part) ? (part as FormPartMulti<Row>).query : (part as FormPartSingle<Row>).record
        )
        .filter((response) => !!response)}
    >
      <FormManagerInner {...props} />
    </ParallelLoad>
  );
};

const FormManagerInner = (props: FormManagerProps) => {
  const dispatch = useDispatch();
  const initialValues = getAllInitialValues(props.parts);
  const Render = props.render || FormContentDefault;
  const onSubmit = async (values: any) => {
    const payload = makeCompoundPayload();
    addRowsToPayload(payload, props.parts, values);
    console.log(payload);
    await getDataProvider().compound(props.compoundUrl, payload);
    resetCacheAll(dispatch, props.parts, values);
    props.onSuccess();
  };
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={props.validate}
      render={(innerProps) => <Render {...innerProps} parts={props.parts} onCancel={props.onCancel} />}
      mutators={{
        ...arrayMutators,
        setRowDelete: (args, state, utils) => utils.changeValue(state, `${args[0]}[${args[1]}]._action`, () => "D"),
      }}
    />
  );
};

const FormContentDefault = (props: FormManagertRenderProps) => {
  return (
    <form onSubmit={props.handleSubmit}>
      {props.submitError && <Alert variant="danger">{props.submitError}</Alert>}
      {props.parts.map((part) => (
        <Section title={part.title} subtitle={part.subtitle} key={getIdentifier(part)}>
          {isMultiRowPart(part) ? (
            <FinalGrid alias={part.alias} entity={part.entity} onAddRow={part.onAddRow} />
          ) : (
            <FinalForm alias={part.alias} entity={part.entity} fieldGroup={part.fieldGroup} isCreate={!part.record} />
          )}
        </Section>
      ))}
      <FinalButtons
        cancel={props.onCancel}
        saveWithOutcome={() => props.handleSubmit()}
        submitting={props.submitting}
      />
    </form>
  );
};
