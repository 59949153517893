import Debug from "debug";
import * as React from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Field, getFieldArray, getListRowLink, getRowKey, Row } from "packages/gossamer-universal";
import { ErrorBoundary, ListCell, ListColumnHeader, SubSectionMultiProps, updateListManagerWithRows } from "../index";
import styles from "./List.module.css";

const debug = Debug("gfe/se/BasicList");

interface BasicListProps<T extends Row> extends SubSectionMultiProps<T> {}

export const BasicList = <T extends {}>(props: BasicListProps<T>): JSX.Element => {
  const navigate = useNavigate();
  const isRowLinks = !!props.entity.frontEndOptions?.listRowLink || !!props.onRowClick;
  const handleRowLinkClick = (key: string) => {
    if (props.onRowClick) {
      props.onRowClick(key);
    } else if (props.entity.frontEndOptions?.listRowLink) {
      if (props.rowClickSetsSearchPaths) {
        updateListManagerWithRows(props.entity, props.rows);
      }
      navigate(getListRowLink(props.entity.frontEndOptions?.listRowLink, key));
    }
  };

  const pageSize = props.queryOptions?.limit || 10;
  const offset = props.queryOptions?.offset || 0;

  const children = props.rows
    .filter((row) => !props.excludeValues || props.excludeValues.indexOf(getRowKey(props.entity, row)) === -1)
    .filter((row, index) => index >= offset && index < offset + pageSize)
    .map((row: any) => {
      const key = getRowKey(props.entity, row);
      // debug(
      //   `row ${key} ${Object.keys(row)}, ${getFieldArray(props.entity)
      //     .filter(([field, _]: [Field, string]) => field.listColumn)
      //     .map(([_, fieldId]) => fieldId)}`
      // );
      const className = isRowLinks ? styles.RowLink : null;
      return (
        <tr key={key} onClick={() => handleRowLinkClick(key)} className={className}>
          {getFieldArray(props.entity)
            .filter(([field, _]: [Field, string]) => field.listColumn)
            .map(([field, fieldId]: [Field, string]) => (
              <ListCell key={fieldId} field={field} fieldId={fieldId} isRowLinks={isRowLinks} row={row} />
            ))}
        </tr>
      );
    });

  if (props.rows.length === 0) {
    return null;
  }
  return (
    <ErrorBoundary>
      <Table responsive striped bordered hover={isRowLinks}>
        <thead>
          <tr>
            {getFieldArray(props.entity)
              .filter(([field, _]: [Field, string]) => field.listColumn)
              .map(([field, fieldId]: [Field, string]) => (
                <ListColumnHeader key={fieldId} entity={props.entity} field={field} fieldId={fieldId} />
              ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </Table>
    </ErrorBoundary>
  );
};
