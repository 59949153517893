import Debug from "debug";
import * as React from "react";
import { filePartsCompose, filePartsGet, getUuid } from "packages/gossamer-universal";
import { FormControl } from "../index";
import styles from "./File.module.css";

const debug = Debug("gfe/fi/File");

export interface FileUneditableProps {
  content: string;
  mimetype: string;
  title: string;
}

export const FileUneditable = (props: FileUneditableProps): JSX.Element => {
  const handleClick = () => {
    window.open(`data:${props.mimetype};base64,${props.content}`);
  };
  if (!props.title) {
    return null;
  }
  return (
    <a onClick={handleClick} className={styles.FileLink}>
      {props.title}
    </a>
  );
};

export interface FileEditableProps {
  className?: string;
  handleChange?: (title: string, mimetype: string, base64: string) => void;
  handleClear?: () => void;
  title: string;
  validity?: boolean;
}

export const FileEditable = (props: FileEditableProps): JSX.Element => {
  const ref = React.useRef<HTMLInputElement>();
  const id = getUuid();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debug(event.target.value);
    debug(ref.current?.files?.length);
    if (ref.current?.files?.length) {
      const file = ref.current.files[0];
      debug(`name: ${file.name}, size: ${file.size}, type: ${file.type}`);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener(
        "load",
        () => {
          const index = (reader.result as string).indexOf(";base64,");
          const base64 = (reader.result as string).substring(index + 8);
          debug(file.name, file.type, base64);
          props.handleChange(file.name, file.type, base64);
        },
        false
      );
      reader.addEventListener("error", (err) => debug(err));
    }
  };

  return (
    <FormControl className={"fileupload"} flexDirection="row" validity={props.validity}>
      <label htmlFor={id}>📎</label>
      <div>{props.title || ""}</div>
      <input
        type="file"
        ref={ref}
        onChange={handleChange}
        id={id}
        style={{
          display: "none",
        }}
      />
      {!!props.title && !!props.handleClear && (
        <button onClick={props.handleClear} className="ControlIcon">
          ✖
        </button>
      )}
    </FormControl>
  );
};

export interface FileProps {
  editable: boolean;
  mandatory: boolean;
  handleChange?: (newValue: string) => void;
  validity?: boolean;
  value: string;
}

export const File = (props: FileProps): JSX.Element => {
  debug(props);
  const { title, mimetype, content } = filePartsGet(props.value);
  if (props.editable) {
    return (
      <FileEditable
        handleChange={(title, mimetype, base64) => props.handleChange(filePartsCompose(title, mimetype, base64))}
        handleClear={() => props.handleChange(null)}
        title={title}
        validity={props.validity}
      />
    );
  }
  return <FileUneditable content={content} mimetype={mimetype} title={title} />;
};
