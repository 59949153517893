import * as React from "react";
import { useSelector } from "react-redux";
import {
  getConfig,
  InMemoryTextSearch,
  PageProps,
  Section,
  selectUser,
  useDocumentTitle,
} from "packages/gossamer-react-redux";
import { Event, EventRow, OrganisationRow, ReservationRow } from "model";
import { EventTile } from "../event/EventTile";
import { EventTileContainer } from "../event/EventTileContainer";
import { HomeBlock } from "front-end/shared/HomeBlock";
import { MainFilters } from "front-end/event/MainFilters";
import { InMemoryPaginator } from "packages/gossamer-react-redux/data/InMemoryPaginator";
import { Link } from "react-router-dom";
import { Spinner, Stack } from "react-bootstrap";
import { useLoadData } from "front-end/shared/useLoadData";
import { QueryResponse } from "packages/gossamer-universal";

export const Home = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  const user = useSelector(selectUser)();
  useDocumentTitle("Home");
  const { eventResp, reservationResp, organisationResp, isError, isFetching, isReady } = useLoadData(user.userId);
  return (
    <>
      <HomeBlock />
      <Stack direction="horizontal" style={{ paddingTop: 10, justifyContent: "space-evenly", textAlign: "center" }}>
        <Link to="/user/points-calc-medical">Points Calculator</Link>
        <Link to="/event/on-demand">On-Demand Events</Link>
        <Link to="/event/create">Create an Event</Link>
      </Stack>
      <InnerFrame>
        <Section>
          {isError && <p>An error occurred loading data, please try again later</p>}
          {isFetching && <Spinner animation="border" size="sm" />}
          {isReady && (
            <HomeInner eventResp={eventResp} organisationResp={organisationResp} reservationResp={reservationResp} />
          )}
        </Section>
      </InnerFrame>
    </>
  );
};

interface HomeInnerProps {
  eventResp: QueryResponse<EventRow>;
  organisationResp: QueryResponse<OrganisationRow>;
  reservationResp: QueryResponse<ReservationRow>;
}

const HomeInner = (props: HomeInnerProps): JSX.Element => {
  const outerFilter = (events: EventRow[]) =>
    events.filter(
      (row) =>
        (row.visibility === "ANYONE" || row.organisation === getConfig("org-path")) &&
        row.distributionType !== "ON-DEMAND"
    );

  return (
    <InMemoryTextSearch
      entity={Event}
      orgData={props.organisationResp.data}
      rows={outerFilter(props.eventResp.data)}
      searchFields={["title", "location", "description", "type", "organisationName"]}
      render={(innerProps) => (
        <MainFilters
          rows={innerProps.rows}
          render={(nanoProps) => (
            <InMemoryPaginator
              pageSize={10}
              rows={nanoProps.rows}
              render={(picoprops) => (
                <EventTileContainer>
                  {picoprops.rows.map((row: EventRow) => {
                    const reservation = props.reservationResp.data?.find(
                      (res: ReservationRow) => res.eventId === row.id
                    );
                    return <EventTile eventRow={row} key={row.id} existingReservationRow={reservation} />;
                  })}
                </EventTileContainer>
              )}
            />
          )}
        />
      )}
    />
  );
};
