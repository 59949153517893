import * as React from "react";
import { InMemoryTextSearch, PageProps, Section, useDocumentTitle } from "packages/gossamer-react-redux";
import { Event, EventRow, OrganisationRow, ReservationRow } from "model";
import { EventTile } from "../event/EventTile";
import { EventTileContainer } from "../event/EventTileContainer";
import { QueryResponse } from "packages/gossamer-universal";
import { HomeBlock } from "front-end/shared/HomeBlock";
import { MainFilters } from "front-end/event/MainFilters";
import { InMemoryPaginator } from "packages/gossamer-react-redux/data/InMemoryPaginator";
import { Spinner, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLoadData } from "front-end/shared/useLoadData";

const outerFilter = (events: EventRow[]) =>
  events.filter((row) => row.visibility === "ANYONE" && row.distributionType !== "ON-DEMAND");

export const Home = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  useDocumentTitle("Home");
  const { eventResp, reservationResp, organisationResp, isError, isFetching, isReady } = useLoadData();
  return (
    <>
      <HomeBlock />
      <Stack direction="horizontal" style={{ paddingTop: 10, justifyContent: "space-evenly" }}>
        <Link to="/event/on-demand">On-Demand Events</Link>
      </Stack>
      <InnerFrame>
        <Section>
          {isError && <p>An error occurred loading data, please try again later</p>}
          {isFetching && <Spinner animation="border" size="sm" />}
          {isReady && (
            <HomeInner eventResp={eventResp} organisationResp={organisationResp} reservationResp={reservationResp} />
          )}
        </Section>
      </InnerFrame>
    </>
  );
};

interface HomeInnerProps {
  eventResp: QueryResponse<EventRow>;
  organisationResp: QueryResponse<OrganisationRow>;
  reservationResp: QueryResponse<ReservationRow>;
}

const HomeInner = (props: HomeInnerProps): JSX.Element => (
  <InMemoryTextSearch
    entity={Event}
    orgData={props.organisationResp.data}
    rows={outerFilter(props.eventResp.data)}
    searchFields={["title", "location", "description", "type"]}
    render={(innerProps) => (
      <MainFilters
        rows={innerProps.rows}
        render={(nanoProps) => (
          <InMemoryPaginator
            pageSize={10}
            rows={nanoProps.rows}
            render={(picoprops) => (
              <EventTileContainer>
                {picoprops.rows.map((row: EventRow) => (
                  <EventTile eventRow={row} key={row.id} />
                ))}
              </EventTileContainer>
            )}
          />
        )}
      />
    )}
  />
);
