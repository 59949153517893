import * as React from "react";
import { useField } from "react-final-form";
import { Field, FieldType, OptionsField, getFieldPrimitiveFromString, validate } from "packages/gossamer-universal";
import { AutoCompleterEntity, DropdownRaw, FIELD_TYPE_MAP, General } from "../index";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";

interface FinalFieldGeneralProps {
  className?: string;
  clearOnSelection?: boolean;
  editable: boolean;
  fullId: string;
  field: Field;
}

export const FinalFieldGeneral = (props: FinalFieldGeneralProps): JSX.Element => {
  const { input, meta } = useField(props.fullId, {
    validate: (value: string) => validate(props.field, getFieldPrimitiveFromString(props.field.type, value)),
    subscription: {
      error: true,
      touched: true,
      value: true,
    },
  });
  // const mandatory = !!props.field.mandatory;
  return (
    <>
      <General
        className={props.className}
        editable={props.editable}
        fieldId={props.fullId}
        field={props.field}
        handleChange={input.onChange}
        validity={meta.touched ? !!meta.error : null}
        value={input.value}
      />
      {meta.error && meta.touched && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
    </>
  );
  /*
  let control;
  if (props.field.type === FieldType.Options && props.editable) {
    if (props.field.excludeValues) {
      (props.field as OptionsField).options.forEach((option) => {
        option.active = props.field.excludeValues.indexOf(option.id) === -1;
      });
    }
    control = (
      <DropdownRaw
        className={props.className}
        options={props.field.options}
        isInvalid={!!meta.error && !!meta.touched}
        isValid={!meta.error}
        mandatory={mandatory}
        onChange={input.onChange}
        value={input.value}
      />
    );
  } else if (props.field.type === FieldType.Reference && props.editable) {
    control = (
      <AutoCompleterEntity
        className={props.className}
        isInvalid={!!meta.error && !!meta.touched}
        isValid={!meta.error}
        entity={props.field.refEntity}
        onItemSelected={input.onChange}
        value={input.value}
      />
    );
  } else {
    control = (
      <FormControl
        className={props.className}
        isInvalid={!!meta.error && !!meta.touched}
        isValid={!meta.error}
        onBlur={input.onBlur}
        onChange={input.onChange}
        placeholder={props.field.label}
        type={FIELD_TYPE_MAP[props.field.type]}
        value={input.value}
      />
    );
  }

  // if (props.field.tooltipText) {
  //   const tooltipText = getTooltipHoverText(props.field, {});
  //   control = (
  //     <OverlayTrigger placement="right" overlay={<Tooltip>{tooltipText}</Tooltip>}>
  //       {({ ref, ...triggerHandler }) => (
  //         <div style={{ display: "flex" }}>
  //           {control}
  //           <span ref={ref} {...triggerHandler}>
  //             ⓘ
  //           </span>
  //         </div>
  //       )}
  //     </OverlayTrigger>
  //   );
  // }

  return (
    <FormGroup id={props.fullId} controlId={props.fullId} className={`${styles.FieldBlock} fieldBlock`}>
      <FormLabel>
        {props.field.label}
        {props.field.mandatory && props.editable ? " *" : ""}
        {props.field.description && (
          <>
            &nbsp;&nbsp;<a title={props.field.description}>ⓘ</a>
          </>
        )}
      </FormLabel>
      {control}
      {meta.error && meta.touched && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
    </FormGroup>
  );
  */
};
