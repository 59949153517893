import {
  DataVolume,
  Entity,
  FieldType,
  FrontEndOptions,
  isUuid,
  Option,
  OptionsControlType,
  PersistenceType,
  SecurityContext,
} from "packages/gossamer-universal";
import { Organisation } from "./Organisation";
import { gradeOptions } from "./SharedOptions";
import { toLocaleDateTimeToMinutes } from "front-end/shared/Helpers";

export interface UserRow {
  emailAddress: string;
  title?: string;
  firstName: string;
  lastName: string;
  grade?: string;
  organisation?: string;
  organisationFreeText?: string;
  createdAt: string;
  medicalPoints?: number;
  surgicalPoints?: number;
  points?: number;
  postgradDegree?: string;
  additionalAchievement?: string;
  presentations?: string;
  publications?: string;
  teachingExperience?: string;
  teachingTraining?: string;
  qualityImprovement?: string;
  leadershipManagement?: string;
  operativeExperience?: string;
  surgicalConferences?: string;
  surgicalExperience?: string;
  clinicalAudit?: string;
  clinicalAuditPresentations?: string;
  presentationsAndPublications?: string;
  surgicalTeachingExperience?: string;
  surgicalTrainingQualifications?: string;
}

export type PointsOption = Option & {
  points: number;
};

// Medical Options

export const PostGradDegreeOptions: PointsOption[] = [
  {
    id: "PHD-OR-MD",
    label: "PhD or MD (by research, using the UK definition of MD), including non-medical related qualifications",
    points: 4,
  },
  {
    id: "MASTERS",
    label: "Masters level degree, including non-medical qualifications (not including intercalation!)",
    points: 3,
  },
  {
    id: "OTHER",
    label:
      "Other postgraduate diploma (PGDip) or certificate (PGCert) related to medicine, for which only graduate entrants can apply. You cannot claim for membership exams such as MRCP",
    points: 1,
  },
  { id: "NONE", label: "None of the above", points: 0 },
];

export const AdditionalAchievementOptions: PointsOption[] = [
  {
    id: "DISTINCTION",
    label: "Honours or distinction in your medical qualification, so long as it is awarded to no more than the top 18%",
    points: 3,
  },
  { id: "PRIZE", label: "National or international prize related to medicine", points: 2 },
  {
    id: "MERIT",
    label:
      "One or more prizes, distinction, or merits in your medical qualification, so long as it is awarded to no more than the top 20%",
    points: 1,
  },
  { id: "NONE", label: "None of the above", points: 0 },
];

export const PresentationsOptions: PointsOption[] = [
  { id: "NATIONAL-ORAL", label: "Oral presentation at a national or international medical meeting", points: 7 },
  { id: "NATIONAL-POSTER", label: "Poster shown at a national or international medical meeting", points: 5 },
  { id: "REGIONAL-ORAL", label: "Oral presentation at a regional medical meeting", points: 5 },
  { id: "REGIONAL-POSTER", label: "Poster at a regional or local medical meeting", points: 2 },
  { id: "LOCAL-ORAL", label: "Oral presentation at a local medical meeting", points: 2 },
  { id: "NONE", label: "None of the above", points: 0 },
];

export const PublicationsOptions: PointsOption[] = [
  {
    id: "1ST-AUTHOR-ORIG-RESEARCH",
    label: "First or joint-first author of one or more PubMed-cited original research publication",
    points: 8,
  },
  {
    id: "CO-AUTHOR-ORIG-RESEARCH",
    label: "Co-author of one or more PubMed-cited original research publication",
    points: 6,
  },
  {
    id: "1ST-AUTHOR-MULTIPLE-ARTICLE",
    label:
      "First or co-author of more than one PubMed-cited publication such as editorials, reviews, case reports, and letters",
    points: 5,
  },
  {
    id: "BOOK-CHAPTER",
    label: "Author of one or more chapters of a book related to medicine (not including self-published books)",
    points: 5,
  },
  {
    id: "1ST-AUTHOR-SINGLE-ARTICLE",
    label: "First or co-author of one PubMed-cited publication such as editorials, reviews, case reports, and letters",
    points: 3,
  },
  {
    id: "NON-PUBMED-ARTICLE",
    label: "Published one or more abstracts or articles that are not PubMed-cited",
    points: 1,
  },
  { id: "NONE", label: "None of the above", points: 0 },
];

export const TeachingExperienceOptions: PointsOption[] = [
  {
    id: "PROGRAMME",
    label:
      "Work with local tutors to organise a teaching programme for healthcare professionals or medical students for three months or longer. You must regularly teach as part of this.",
    points: 6,
  },
  {
    id: "REGULAR",
    label: "Provide regular teaching for healthcare professionals or medical students for three months or longer.",
    points: 3,
  },
  { id: "OCCASIONAL", label: "Occasional teaching for healthcare professionals or medical students", points: 1 },
  { id: "NONE", label: "None of the above", points: 0 },
];

export const TeachingTrainingOptions: PointsOption[] = [
  { id: "MASTERS", label: "Masters level teaching qualification", points: 3 },
  { id: "HIGHER", label: "Higher teaching qualification such as PGDip or PGCert", points: 2 },
  { id: "OTHER", label: "Training in teaching which doesn’t qualify for 2 points", points: 1 },
  { id: "NONE", label: "None of the above", points: 0 },
];

export const QualityImprovementOptions: PointsOption[] = [
  { id: "ALL-MULTI", label: "Involvement in all aspects of a QIP, for two or more cycles", points: 5 },
  { id: "SOME-MULTI", label: "Involvement in some stages of two cycles of a QIP", points: 3 },
  { id: "ALL-SINGLE", label: "Involvement in all stages of a single cycle QIP", points: 3 },
  { id: "SOME-SINGLE", label: "Involvement in some stages of a single cycle QIP", points: 1 },
  { id: "NONE", label: "None of the above", points: 0 },
];

export const LeadershipManagementOptions: PointsOption[] = [
  {
    id: "REGIONAL",
    label: "Held a national or regional leadership or managerial role for 6 or more months",
    points: 4,
  },
  { id: "LOCAL", label: "held a local leadership or managerial role for 6 or more months", points: 2 },
  { id: "NONE", label: "None of the above", points: 0 },
];

// Surgical Options

export const OperativeExperienceOptions: PointsOption[] = [
  { id: "CASES-40-OR-MORE", label: "Involvement in 40 or more cases", points: 8 },
  { id: "CASES-30-TO-39", label: "Involvement in 30-39 cases or more", points: 6 },
  { id: "CASES-20-TO-29", label: "Involvement in 20-29 cases", points: 4 },
  { id: "CASES-11-TO-19", label: "Involvement in 11-19 cases", points: 2 },
  { id: "CASES-LESS-THAN-11", label: "Involved in less than 10 cases/no evidence", points: 0 },
];

export const SurgicalConferenceOptions: PointsOption[] = [
  { id: "CONFS-3-PLUS", label: "I have attended 3 or more surgical conferences", points: 3 },
  { id: "CONFS-2", label: "I have attended 2 surgical conferences", points: 2 },
  { id: "CONFS-1", label: "I have attended 1 surgical conference", points: 1 },
  { id: "CONFS-0", label: "I have not attended any surgical conferences", points: 0 },
];

export const SurgicalExperienceOptions: PointsOption[] = [
  {
    id: "TASTER-WEEK",
    label: "I have attended a surgical taster week (minimum 5 days, can be non-consecutive days)",
    points: 3,
  },
  {
    id: "ELECTIVE-OR-PLACEMENT",
    label:
      "I have undertaken an elective in a surgical specialty (minimum 4 weeks) or a surgical placement during my foundation training or equivalent (minimum 12 weeks)",
    points: 2,
  },
  { id: "NONE", label: "I have not undertaken a surgical taster/elective", points: 0 },
];

export const ClinicalAuditOptions: PointsOption[] = [
  {
    id: "LEAD-ALL-SURGICAL-THEMED",
    label:
      "I was involved as Lead in ALL aspects of a surgically themed clinical audit or QI project that has demonstrated change (such as second cycle/closed audit loop)",
    points: 8,
  },
  {
    id: "LEAD-ALL",
    label:
      "I was involved as Lead in ALL aspects of a clinical audit or QI project that has demonstrated change (such as second cycle/ closed audit loop)",
    points: 6,
  },
  {
    id: "CONTRIBUTOR",
    label:
      "I was involved as a contributor in a clinical audit or QI project that has demonstrated change (such as second cycle/ closed audit loop)",
    points: 4,
  },
  { id: "INVOLVED", label: "I was involved in a clinical audit or QI project", points: 2 },
  { id: "NONE", label: "None/other", points: 0 },
];

export const ClinicalAuditPresentationOptions: PointsOption[] = [
  { id: "NATIONAL", label: "I presented the above project at national or international meeting", points: 5 },
  { id: "REGIONAL", label: "presented the project at a regional meeting", points: 3 },
  { id: "LOCAL", label: "presented the project at a local meeting", points: 1 },
  { id: "NONE", label: "None of the above", points: 0 },
];

export const PresentationsAndPublicationsOptions: PointsOption[] = [
  {
    id: "ORAL-PRES-PRIZE",
    label:
      "I have won a prize for delivering an oral presentation at a national or international medical meeting convened by an accredited institution after being invited/selected to do so",
    points: 10,
  },
  {
    id: "PUBLICATION-FIRST-AUTHOR",
    label:
      "I am first author of a PubMed-cited publication (or in press) not including a case report or editorial letter",
    points: 10,
  },
  {
    id: "ORAL-PRES-NATIONAL",
    label:
      "I have delivered an oral presentation at a national or international medical meeting convened by an accredited institution after being invited/selected to do so",
    points: 8,
  },
  {
    id: "FIRST-AUTHOR-OR-PRIZE-POSTER",
    label:
      "I am first author of a prize-winning poster or oral poster presentations presented at an international or national medical meeting convened by an accredited institution after being invited/selected to do so",
    points: 6,
  },
  {
    id: "POSTER-NATIONAL",
    label:
      "I am first author for 2 or more posters or oral poster presentations presented at an international or national medical meeting convened by an accredited institution after being invited/selected to do so",
    points: 4,
  },
  {
    id: "CASE-REPORT-LETTER-BOOK",
    label:
      "I am first author of a PubMed-cited publication of a case report or editorial letter (or in press) or I have written a book chapter related to medicine which has been published (not self-published)",
    points: 4,
  },
  {
    id: "CITED-PUBLICATION",
    label:
      "I am a Cited Collaborative author as part of a research collaborative publication in 3 or more PubMed cited publications",
    points: 3,
  },
  { id: "NAMED-CO-AUTHOR", label: "I am a named co-author of one PubMed-cited publication (or in press)", points: 2 },
  {
    id: "ORAL-REGIONAL",
    label: "I have given an oral presentation at a regional medical meeting after being invited/selected to do so",
    points: 2,
  },
  {
    id: "POSTER-REGIONAL",
    label:
      "I have presented one or more posters as first author at a regional medical meeting(s) after being invited/selected to do so or I have had a poster accepted for presentation at a national or international meeting but did not attend",
    points: 1,
  },
  {
    id: "COLLABORATIVE-AUTHOR",
    label:
      "I am a Cited Collaborative author as part of a research collaborative publication in 2 or more PubMed cited publications",
    points: 1,
  },
  { id: "NONE", label: "None/other", points: 0 },
];

export const SurgicalTeachingExperienceOptions: PointsOption[] = [
  {
    id: "ORGANISE-FACE-TO-FACE",
    label:
      "I have worked with local educators to design and organise a face-to-face teaching programme (a series of sessions defined as 4 or more) to enhance organised teaching for healthcare professionals or medical students at a regional level",
    points: 10,
  },
  {
    id: "ORGANISE-ONLINE",
    label:
      "I have worked with local educators to design and organise a teaching programme (a series of sessions defined as 4 or more) to enhance organised teaching for healthcare professionals or medical students in an online format to a regional or larger audience",
    points: 8,
  },
  {
    id: "ORGANISE-LOCAL",
    label:
      "I have worked with local educators to design and organise a teaching programme (a series of sessions defined as 4 or more) to enhance organised teaching for healthcare professionals or medical students at a local level",
    points: 6,
  },
  {
    id: "REGULAR-TEACHING",
    label:
      "I have provided regular teaching for healthcare professionals or medical students over the last year (4 or more sessions/year).",
    points: 2,
  },
  { id: "NONE", label: "None of the above", points: 0 },
];

export const SurgicalTrainingQualificationsOptions: PointsOption[] = [
  {
    id: "POSTGRAD",
    label:
      "I have a Teaching specific postgraduate qualification, for example, at least a PG Cert (ICSED level 4 and above)",
    points: 5,
  },
  {
    id: "SUBSTANTIAL",
    label:
      "I have had substantial training (defined in additional notes) in teaching methods lasting at least 2 days. This could include a completed module which forms part of a postgraduate teaching qualification or masters level programme",
    points: 3,
  },
  { id: "TRAINING", label: "I have had training in teaching methods.", points: 1 },
  { id: "NONE", label: "None of the above", points: 0 },
];

export const User: Entity<UserRow> = {
  id: "User",
  title: "User",
  service: "main",
  rowLabel: (row: UserRow) => `${row.firstName} ${row.lastName}`,
  primaryKeyFields: ["emailAddress"],
  security: [
    { role: "admin", action: "all", access: "all" },
    {
      role: "normal",
      action: "all",
      access: "test",
      test: (row: UserRow, sctx: SecurityContext) => {
        const out = row.emailAddress === sctx.userId;
        console.log(`inside event security rule`, row.emailAddress, sctx.userId, out);
        return out;
      },
    },
  ],
  defaultSort: [["emailAddress", "DESC"]],
  defaultFilters: [{ fieldId: "emailAddress", operator: "ct", value: "" }],
  dataVolume: DataVolume.Medium,
  frontEndOptions: {
    listRowLink: "/user/display/{key}",
    skipReferenceLookup: (key: string) => !isUuid(key),
  } as FrontEndOptions<UserRow>,
  backEndOptions: {
    type: "table",
  },
  fields: {
    emailAddress: {
      seq: 5,
      type: FieldType.Text,
      label: "Email Address",
      fieldGroup: "main",
      mandatory: true,
      listColumn: true,
      maxLength: 80,
      persistence: PersistenceType.SetOnCreateOnly,
      regex: "@",
      regexFailText: "must be a valid email address",
    },
    title: {
      seq: 10,
      type: FieldType.Options,
      label: "Title",
      fieldGroup: "main",
      options: [
        { id: "MR", label: "Mr" },
        { id: "MRS", label: "Mrs" },
        { id: "MS", label: "Ms" },
        { id: "DR", label: "Dr" },
        { id: "PROF", label: "Prof" },
      ],
    },
    firstName: {
      seq: 15,
      type: FieldType.Text,
      label: "First Name",
      fieldGroup: "main",
      mandatory: true,
      // listColumn: true,
      maxLength: 80,
    },
    lastName: {
      seq: 20,
      type: FieldType.Text,
      label: "Last Name",
      fieldGroup: "main",
      mandatory: true,
      // listColumn: true,
      maxLength: 80,
    },
    grade: {
      seq: 25,
      type: FieldType.Options,
      label: "Grade",
      fieldGroup: "main",
      options: gradeOptions,
    },
    organisation: {
      seq: 30,
      type: FieldType.Reference,
      label: "Organisation",
      fieldGroup: "main",
      refEntity: Organisation,
      // persistence: PersistenceType.BackEndManaged,
    },
    organisationFreeText: {
      seq: 32,
      type: FieldType.Text,
      label: "Organisation (if not in list)",
      fieldGroup: "main",
    },
    createdAt: {
      seq: 33,
      type: FieldType.Datetime,
      label: "Created At",
      fieldGroup: "main",
      persistence: PersistenceType.FrontEndGenerated,
      evaluateFrontEndCreate: () => new Date().toISOString(),
      evaluateFrontEndDisplay: (val: string) => toLocaleDateTimeToMinutes(val),
    },
    medicalPoints: {
      seq: 35,
      type: FieldType.Integer,
      label: "Medical Points",
      fieldGroup: "medical",
      persistence: PersistenceType.FrontEndGenerated,
    },
    surgicalPoints: {
      seq: 40,
      type: FieldType.Integer,
      label: "Points",
      fieldGroup: "surgical",
      persistence: PersistenceType.FrontEndGenerated,
    },
    points: {
      seq: 45,
      type: FieldType.Integer,
      label: "Total Points",
      fieldGroup: "main",
      persistence: PersistenceType.FrontEndGenerated,
    },
    postgradDegree: {
      seq: 50,
      type: FieldType.Options,
      label: "Postgraduate Degree",
      fieldGroup: "medical",
      options: PostGradDegreeOptions,
      controlType: OptionsControlType.RadioVertical,
      showEvenIfBlank: true,
    },
    additionalAchievement: {
      seq: 55,
      type: FieldType.Options,
      label: "Additional Achievement",
      fieldGroup: "medical",
      options: AdditionalAchievementOptions,
      controlType: OptionsControlType.RadioVertical,
      showEvenIfBlank: true,
    },
    presentations: {
      seq: 60,
      type: FieldType.Options,
      label: "Presentations",
      fieldGroup: "medical",
      options: PresentationsOptions,
      controlType: OptionsControlType.RadioVertical,
      showEvenIfBlank: true,
    },
    publications: {
      seq: 65,
      type: FieldType.Options,
      label: "Publications",
      fieldGroup: "medical",
      options: PublicationsOptions,
      controlType: OptionsControlType.RadioVertical,
      showEvenIfBlank: true,
    },
    teachingExperience: {
      seq: 70,
      type: FieldType.Options,
      label: "Teaching Experience",
      fieldGroup: "medical",
      options: TeachingExperienceOptions,
      controlType: OptionsControlType.RadioVertical,
      showEvenIfBlank: true,
    },
    teachingTraining: {
      seq: 75,
      type: FieldType.Options,
      label: "Teaching Training",
      fieldGroup: "medical",
      options: TeachingTrainingOptions,
      controlType: OptionsControlType.RadioVertical,
      showEvenIfBlank: true,
    },
    qualityImprovement: {
      seq: 80,
      type: FieldType.Options,
      label: "Quality Improvement",
      fieldGroup: "medical",
      options: QualityImprovementOptions,
      controlType: OptionsControlType.RadioVertical,
      showEvenIfBlank: true,
    },
    leadershipManagement: {
      seq: 85,
      type: FieldType.Options,
      label: "Leadership Management",
      fieldGroup: "medical",
      options: LeadershipManagementOptions,
      controlType: OptionsControlType.RadioVertical,
      showEvenIfBlank: true,
    },

    operativeExperience: {
      seq: 100,
      type: FieldType.Options,
      label: "Operative Experience",
      fieldGroup: "surgical",
      options: OperativeExperienceOptions,
      controlType: OptionsControlType.RadioVertical,
      showEvenIfBlank: true,
    },
    surgicalConferences: {
      seq: 105,
      type: FieldType.Options,
      label: "Surgical Conferences",
      fieldGroup: "surgical",
      options: SurgicalConferenceOptions,
      controlType: OptionsControlType.RadioVertical,
      showEvenIfBlank: true,
    },
    surgicalExperience: {
      seq: 110,
      type: FieldType.Options,
      label: "Surgical Experience",
      fieldGroup: "surgical",
      options: SurgicalExperienceOptions,
      controlType: OptionsControlType.RadioVertical,
      showEvenIfBlank: true,
    },

    clinicalAudit: {
      seq: 115,
      type: FieldType.Options,
      label: "Clinical Audit",
      fieldGroup: "surgical",
      options: ClinicalAuditOptions,
      controlType: OptionsControlType.RadioVertical,
      showEvenIfBlank: true,
    },
    clinicalAuditPresentations: {
      seq: 120,
      type: FieldType.Options,
      label: "Clinical Audit Presentations",
      fieldGroup: "surgical",
      options: ClinicalAuditPresentationOptions,
      controlType: OptionsControlType.RadioVertical,
      showEvenIfBlank: true,
    },
    presentationsAndPublications: {
      seq: 125,
      type: FieldType.Options,
      label: "Presentations and Publications",
      fieldGroup: "surgical",
      options: PresentationsAndPublicationsOptions,
      controlType: OptionsControlType.RadioVertical,
      showEvenIfBlank: true,
    },
    surgicalTeachingExperience: {
      seq: 130,
      type: FieldType.Options,
      label: "Surgical Teaching Experience",
      fieldGroup: "surgical",
      options: SurgicalTeachingExperienceOptions,
      controlType: OptionsControlType.RadioVertical,
      showEvenIfBlank: true,
    },
    surgicalTrainingQualifications: {
      seq: 135,
      type: FieldType.Options,
      label: "Surgical Training Qualifications",
      fieldGroup: "surgical",
      options: SurgicalTrainingQualificationsOptions,
      controlType: OptionsControlType.RadioVertical,
      showEvenIfBlank: true,
    },
  },
};
