import Debug from "debug";
import { createAction, createReducer } from "@reduxjs/toolkit";
import { Primitive, Entity } from "packages/gossamer-universal";

const debug = Debug("gfe/da/ReduxGCache");

// types
export interface GCacheState {
  records: Record<string, GCacheRecord<{}>>;
  queries: Record<string, GCacheQuery<{}>>;
}

export interface GCacheQuery<T> {
  entityId: string;
  query: string;
  data: T[] | undefined;
  error: string | undefined;
  isFetching: boolean;
  expiresAt: number;
  frontEndEvaluatesApplied: boolean;
}

export interface GCacheRecord<T> {
  entityId: string;
  key: string;
  data: T | undefined;
  error: string | undefined;
  isFetching: boolean;
  expiresAt: number;
  frontEndEvaluatesApplied: boolean;
}

// export interface GCacheEntity<T extends {}> {
//   id: string;
//   service: string;
//   getRowKey: (data: T) => string;
//   timeToLiveQueryMillis: number;
//   timeToLiveRecordMillis: number;
//   retrieveAllDataInSingleQuery: boolean;
// }

// export const getGCacheEntity = <T extends {}>(entity: Entity<T>): GCacheEntity<T> => {
//   return {
//     id: entity.id,
//     service: entity.service,
//     getRowKey: (data: T) => entity.primaryKeyFields.map((field) => data[field]).join("."),
//     timeToLiveQueryMillis: 5 * 14 * 1000,
//     timeToLiveRecordMillis: 30 * 14 * 1000,
//     retrieveAllDataInSingleQuery: entity.dataVolume === DataVolume.Small,
//   };
// };

// initial state
const INITIAL_STATE: GCacheState = {
  records: {},
  queries: {},
};

// Actions

export interface AddRecordPayload {
  entityId: string;
  key: string;
}

export const addRecordToGCache = createAction<AddRecordPayload>("GCache/addRecord");

export interface SetRecordDataPayload {
  entityId: string;
  key: string;
  data: Record<string, Primitive>;
  expiresAt: number;
}

export const setRecordDataInGCache = createAction<SetRecordDataPayload>("GCache/setRecordData");

export interface SetRecordErrorPayload {
  entityId: string;
  key: string;
  error: string;
}

export const setRecordErrorInGCache = createAction<SetRecordErrorPayload>("GCache/setRecordError");

export interface GetRecordPayload {
  entity: Entity<{}>;
  key: string;
}

export const getRecordFromGCache = createAction<GetRecordPayload>("GCache/getRecord");

export interface ClearSingleRecordPayload {
  entityId: string;
  key: string;
}

export const clearSingleRecordInGCache = createAction<ClearSingleRecordPayload>("GCache/clearSingleRecord");

export interface ClearAllRecordsPayload {
  entityId: string;
}

export const clearAllRecordsInGCache = createAction<ClearAllRecordsPayload>("GCache/clearAllRecords");

export interface AddQueryPayload {
  entityId: string;
  query: string;
}

export const addQueryToGCache = createAction<AddQueryPayload>("GCache/addQuery");

export interface SetQueryDataPayload {
  entityId: string;
  query: string;
  data: Record<string, Primitive>[];
  keys: string[];
  expiresAt: number;
}

export const setQueryDataInGCache = createAction<SetQueryDataPayload>("GCache/setQueryData");

export interface SetQueryErrorPayload {
  entityId: string;
  query: string;
  error: string;
}

export const setQueryErrorInGCache = createAction<SetQueryErrorPayload>("GCache/setQueryError");

export interface GetQueryPayload {
  entity: Entity<{}>;
  query: any;
}

export const getQueryFromGCache = createAction<GetQueryPayload>("GCache/getQuery");

export interface ClearSingleQueryPayload {
  entityId: string;
  query: string;
}

export const clearSingleQueryInGCache = createAction<ClearSingleQueryPayload>("GCache/clearSingleQuery");

export interface ClearAllQueriesPayload {
  entityId: string;
}

export const clearAllQueriesInGCache = createAction<ClearAllQueriesPayload>("GCache/clearAllQueries");

// middleware-only action, has no reducer
export const cacheSweep = createAction<{}>("GCache/cacheSweep");

export const makeRecordGCacheKey = (entityId: string, key: string) => [entityId, key].join("|");

export const makeQueryGCacheKey = (entityId: string, query: string) => [entityId, query].join("|");

export const getDefaultGCacheRecordEntry = <T>(entityId: string, key: string): GCacheRecord<T> => ({
  entityId,
  key,
  data: undefined,
  error: undefined,
  isFetching: true,
  expiresAt: Number.POSITIVE_INFINITY,
  frontEndEvaluatesApplied: true,
});

export const getDefaultGCacheQueryEntry = <T>(entityId: string, query: string): GCacheQuery<T> => ({
  entityId,
  query,
  data: undefined,
  error: undefined,
  isFetching: true,
  expiresAt: Number.POSITIVE_INFINITY,
  frontEndEvaluatesApplied: true,
});

export const cacheReducer = createReducer(INITIAL_STATE, (builder) => {
  builder

    .addCase(addRecordToGCache, (state, action) => {
      debug(`addRecordToGCache(${action.payload.entityId}, ${action.payload.key})`);
      const entry: GCacheRecord<{}> = getDefaultGCacheRecordEntry(action.payload.entityId, action.payload.key);
      state.records[makeRecordGCacheKey(action.payload.entityId, action.payload.key)] = entry;
    })

    .addCase(setRecordDataInGCache, (state, action) => {
      const cacheKey = makeRecordGCacheKey(action.payload.entityId, action.payload.key);
      debug(`setRecordDataInGCache(${cacheKey})`);
      const entry: GCacheRecord<{}> = (state.records[cacheKey] =
        state.records[cacheKey] || getDefaultGCacheRecordEntry(action.payload.entityId, action.payload.key));
      entry.data = action.payload.data;
      entry.expiresAt = action.payload.expiresAt;
      entry.isFetching = false;
    })

    .addCase(setRecordErrorInGCache, (state, action) => {
      debug(`setRecordErrorInGCache(${action.payload.entityId}, ${action.payload.key})`);
      const entry: GCacheRecord<{}> = state.records[makeRecordGCacheKey(action.payload.entityId, action.payload.key)];
      entry.error = action.payload.error;
      entry.isFetching = false;
    })

    .addCase(clearSingleRecordInGCache, (state, action) => {
      debug(`clearSingleRecordInGCache(${action.payload.entityId}, ${action.payload.key})`);
      delete state.records[makeRecordGCacheKey(action.payload.entityId, action.payload.key)];
    })

    .addCase(clearAllRecordsInGCache, (state, action) => {
      debug(`clearAllRecordsInGCache(${action.payload.entityId})`);
      const cacheKeys = Object.keys(state.records);
      cacheKeys
        .filter((cacheKey) => cacheKey.indexOf(action.payload.entityId + "|"))
        .forEach((cacheKey) => delete state.records[cacheKey]);
    })

    .addCase(addQueryToGCache, (state, action) => {
      debug(`addQueryToGCache(${action.payload.entityId}, ${action.payload.query})`);
      const entry: GCacheQuery<{}> = getDefaultGCacheQueryEntry(action.payload.entityId, action.payload.query);
      state.queries[makeQueryGCacheKey(action.payload.entityId, action.payload.query)] = entry;
    })

    .addCase(setQueryDataInGCache, (state, action) => {
      const cacheKey = makeQueryGCacheKey(action.payload.entityId, action.payload.query);
      debug(`setQueryDataInGCache(${cacheKey})`);
      const entry: GCacheQuery<{}> = (state.queries[cacheKey] =
        state.queries[cacheKey] || getDefaultGCacheQueryEntry(action.payload.entityId, action.payload.query));
      entry.data = action.payload.data;
      entry.expiresAt = action.payload.expiresAt;
      entry.isFetching = false;

      entry.data.forEach((row, index) => {
        const key = action.payload.keys[index];
        const cacheKey2 = makeRecordGCacheKey(action.payload.entityId, key);
        // debug(`setQueryDataInGCache add record(${cacheKey2})`);
        const entry2: GCacheRecord<{}> = (state.records[cacheKey2] =
          state.records[cacheKey2] || getDefaultGCacheRecordEntry(action.payload.entityId, key));
        entry2.data = row;
        entry2.expiresAt = action.payload.expiresAt;
        entry2.isFetching = false;
      });
    })

    .addCase(setQueryErrorInGCache, (state, action) => {
      debug(`setQueryErrorInGCache(${action.payload.entityId}, ${action.payload.query})`);
      const entry: GCacheQuery<{}> = state.queries[makeQueryGCacheKey(action.payload.entityId, action.payload.query)];
      entry.error = action.payload.error;
      entry.isFetching = false;
    })

    .addCase(clearSingleQueryInGCache, (state, action) => {
      debug(`clearSingleQueryInGCache(${action.payload.entityId}, ${action.payload.query})`);
      delete state.queries[makeQueryGCacheKey(action.payload.entityId, action.payload.query)];
    })

    .addCase(clearAllQueriesInGCache, (state, action) => {
      debug(`clearAllQueriesInGCache(${action.payload.entityId})`);
      const cacheKeys = Object.keys(state.queries);
      cacheKeys
        .filter((cacheKey) => cacheKey.indexOf(action.payload.entityId + "|") > -1)
        .forEach((cacheKey) => delete state.queries[cacheKey]);
    });
});

// Selectors
export const selectRecordFromGCache = (state: { cache: GCacheState }) => (entityId: string, key: string) =>
  state.cache.records[makeRecordGCacheKey(entityId, key)] || getDefaultGCacheRecordEntry(entityId, key);

export const selectQueryFromGCache = (state: { cache: GCacheState }) => (entityId: string, query: string) =>
  state.cache.queries[makeQueryGCacheKey(entityId, query)] || getDefaultGCacheQueryEntry(entityId, query);
