import Debug from "debug";
import * as React from "react";
import { Image as BSImage } from "react-bootstrap";
import { filePartsCompose, filePartsGet, getUuid } from "packages/gossamer-universal";
import { FormControl } from "../index";

const debug = Debug("gfe/fi/Image");

interface ImageUneditableProps {
  value: string;
}

export const ImageUneditable = (props: ImageUneditableProps): JSX.Element => {
  if (!props.value) {
    return null;
  }
  return <BSImage fluid={true} thumbnail={true} src={props.value}></BSImage>;
};

export interface ImageEditableProps {
  className?: string;
  handleChange?: (title: string, mimetype: string, base64: string) => void;
  handleClear?: () => void;
  title: string;
  validity?: boolean;
}

export const ImageEditable = (props: ImageEditableProps): JSX.Element => {
  const ref = React.useRef<HTMLInputElement>();
  const id = getUuid();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debug(event.target.value);
    debug(ref.current?.files?.length);
    if (ref.current?.files?.length) {
      const file = ref.current.files[0];
      debug(`name: ${file.name}, size: ${file.size}, type: ${file.type}`);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener(
        "load",
        () => {
          const index = (reader.result as string).indexOf(";base64,");
          const base64 = (reader.result as string).substring(index + 8);
          debug(file.name, file.type, base64);
          props.handleChange(file.name, file.type, base64);
        },
        false
      );
      reader.addEventListener("error", (err) => debug(err));
    }
  };

  return (
    <FormControl className={props.className} flexDirection="row" validity={props.validity}>
      <label htmlFor={id}>📎</label>
      <div>{props.title || ""}</div>
      <input
        type="file"
        ref={ref}
        onChange={handleChange}
        id={id}
        style={{
          display: "none",
        }}
      />
      {!!props.title && !!props.handleClear && (
        <button onClick={props.handleClear} className="ControlIcon">
          ✖
        </button>
      )}
    </FormControl>
  );
};

export interface ImageProps {
  editable: boolean;
  mandatory: boolean;
  handleChange?: (newValue: string) => void;
  validity?: boolean;
  value: string;
}

export const Image = (props: ImageProps): JSX.Element => {
  // debug(props);
  const { title, mimetype, content } = filePartsGet(props.value);
  return (
    <>
      {props.editable && (
        <ImageEditable
          handleChange={(title, mimetype, base64) => props.handleChange(filePartsCompose(title, mimetype, base64))}
          handleClear={() => props.handleChange(null)}
          title={title}
          validity={props.validity}
        />
      )}
      {!props.editable && <ImageUneditable value={`data:${mimetype};base64, ${content}`} />}
    </>
  );
};
