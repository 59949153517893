import { parse } from "date-fns";
import * as React from "react";
import { useSelector } from "react-redux";
import {
  PageProps,
  PageTitle,
  Section,
  selectIsAdmin,
  selectUser,
  TransButtons,
  useTrans,
} from "packages/gossamer-react-redux";
import { Event, EventRow } from "model";
import { FieldType, OptionsField } from "packages/gossamer-universal";

const COLUMN_SEQUENCE = [
  "type",
  "title",
  "startsAt",
  "endsAt",

  "distributionType",
  "description",
  "specialism",
  "grade",
  "location",
  "price",
  "visibility",
  "bookingMethod",
  "capacity",
  "bookingUrl",

  // conference only
  "eventScale",
  "allowsOralPresentation",
  "allowsPosterPresentation",
  "clinicalAuditPresentations",

  // course only
  "teachingProgramme",
  "teachingProgrammeDuration",
  "acceptCoTutorOffers",
  "teachingQualification",

  "leadershipAndManagement",

  // admin only
  "organiser",
  "organisation",
];

const toDateOrNull = (str: string) => {
  try {
    return str ? parse(str, "dd/MM/yyyy HH:mm:ss", new Date()).toISOString() : null;
    // return str ? new Date(str).toISOString() : null;
  } catch (e) {
    return str;
  }
};

const isEventType = (str: string) => !!(Event.fields.type as OptionsField).options.find((opt) => opt.id === str.trim());

const findNextRow = (allCells: string[]): Partial<EventRow> | null => {
  while (allCells.length > 0 && !isEventType(allCells[0])) {
    allCells.shift();
  }
  if (allCells.length === 0) {
    return null;
  }
  const row: Partial<EventRow> = {
    type: allCells.shift().trim(),
    title: allCells.shift().trim(),
    startsAt: toDateOrNull(allCells.shift()),
    endsAt: toDateOrNull(allCells.shift()),
    distributionType: "IN-PERSON",
    visibility: "ANYONE",
    bookingMethod: "BY-EMAIL",
  };
  let optionalCol = 4;
  while (allCells.length > 0 && optionalCol < COLUMN_SEQUENCE.length && !isEventType(allCells[0])) {
    row[COLUMN_SEQUENCE[optionalCol]] = allCells.shift().trim();
    optionalCol += 1;
  }
  console.log(`findNextRow`, row, allCells);
  return row;
};

interface RowOutcome {
  isValid: boolean;
  title: string;
  validationSummary?: string;
}

export const BulkUpload2 = (props: PageProps): JSX.Element => {
  const { cancel, saveWithOutcome, trans } = useTrans({
    followOnURL: "/",
  });
  const [rowOutcomes, setRowOutcomes] = React.useState<RowOutcome[]>([]);
  const isAdmin = useSelector(selectIsAdmin)();
  const InnerFrame = props.innerFrame;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = event.target.value;
    if (!data) {
      console.warn("no data");
      return;
    }
    event.target.value = "";
    const allCells = data.split("\t"); // annoyingly pasting from a spreadsheet has no line terminator
    const outcomes = [];
    let rawRow;
    while ((rawRow = findNextRow(allCells))) {
      console.log(rawRow);
      const out: RowOutcome = {
        isValid: false,
        title: rawRow.title,
      };
      try {
        const row = trans.makeCreateRow(Event);
        Object.keys(rawRow)
          .filter((fieldId) => !!rawRow[fieldId])
          .forEach((fieldId) => {
            const val = Event.fields[fieldId].type === FieldType.Boolean ? rawRow[fieldId] === "YES" : rawRow[fieldId];
            console.log(fieldId, val);
            row.setFieldValue(fieldId, val);
          });
        if (isAdmin && rawRow.organiser) {
          row.setFieldValue("organiser", rawRow.organiser);
        }
        if (isAdmin && rawRow.organisation) {
          row.setFieldValue("organisation", rawRow.organisation);
        }
        const validations = row.getValidations();
        out.isValid = validations.length === 0;
        if (!out.isValid) {
          out.validationSummary = validations.map(([fieldId, msg, val]) => `${fieldId}: '${val}' ${msg}`).join(", ");
          row.setDelete(true);
        }
      } catch (e) {
        console.error(e);
        out.validationSummary = e.toString();
      }
      outcomes.push(out);
    }
    setRowOutcomes(outcomes);
  };
  return (
    <InnerFrame>
      <PageTitle title="Event Bulk Upload" />
      <Section>
        <div>
          <p>Click on this box and paste your data:</p>
          <input type="text" onChange={handleChange} disabled={rowOutcomes.length > 0} />
        </div>
        <table>
          <thead>
            <tr>
              <th>Row</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {rowOutcomes.map((outcome, index) => (
              <tr key={String(index)}>
                <td>{index + 1}</td>
                <td>{outcome.isValid ? outcome.title + ": valid" : outcome.validationSummary}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {rowOutcomes.length > 0 && (
          <p>
            Click 'save' to save the valid rows (the invalid rows won't be saved - you can correct and upload them
            again), or click 'cancel' to skip saving any of them.
          </p>
        )}
      </Section>
      <TransButtons cancel={cancel} saveWithOutcome={saveWithOutcome} trans={trans} />
    </InnerFrame>
  );
};
