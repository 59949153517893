import * as React from "react";
import { Link, Outlet } from "react-router-dom";
import { Button, Offcanvas, Stack } from "react-bootstrap";
import { useNavigate } from "react-router";
import { DisplayToast, getAuthProvider, selectIsAdmin } from "packages/gossamer-react-redux";
import { Logo } from "front-end/shared/Logo";
import { useSelector } from "react-redux";

export const PageFrame = (): JSX.Element => {
  const navigate = useNavigate();
  const isAdmin = useSelector(selectIsAdmin)();
  const [showMenu, setShowMenu] = React.useState(false);
  const handleMenuClose = () => setShowMenu(false);
  return (
    <div style={{ marginBottom: 120 }}>
      <header
        style={{
          backgroundColor: "var(--bs-dark)",
          borderBottom: "unset",
          minHeight: 58,
        }}
      >
        <Stack
          direction="horizontal"
          style={{
            justifyContent: "space-between",
          }}
        >
          <Logo />
          <img
            src="/hamburger.svg"
            onClick={setShowMenu.bind(null, true)}
            style={{
              paddingRight: 5,
            }}
          />
          <Offcanvas show={showMenu} onHide={handleMenuClose} placement="end">
            <Offcanvas.Header closeButton>
              <h1>Menu</h1>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Stack direction="vertical">
                <Link to="/" onClick={handleMenuClose}>
                  <Button variant="link">Home</Button>
                </Link>
                <Link to="/event/favourited" onClick={handleMenuClose}>
                  <Button variant="link">Favourites</Button>
                </Link>
                <Link to="/event/reserved" onClick={handleMenuClose}>
                  <Button variant="link">Reserved</Button>
                </Link>
                <Link to="/event/organising" onClick={handleMenuClose}>
                  <Button variant="link">My Events</Button>
                </Link>
                <Link to="/event/on-demand" onClick={handleMenuClose}>
                  <Button variant="link">On-Demand Events</Button>
                </Link>
                <Link to="/event/create" onClick={handleMenuClose}>
                  <Button variant="link">Create Course or Conference</Button>
                </Link>
                <Link to="/user/profile-main" onClick={handleMenuClose}>
                  <Button variant="link">Profile</Button>
                </Link>
                <Link to="/user/points-calc-medical" onClick={handleMenuClose}>
                  <Button variant="link">Points Calculator</Button>
                </Link>
                <Link to="/how-it-works" onClick={handleMenuClose}>
                  <Button variant="link">How it works</Button>
                </Link>
                <Link to="/about" onClick={handleMenuClose}>
                  <Button variant="link">About us</Button>
                </Link>
                <Link to="/contact" onClick={handleMenuClose}>
                  <Button variant="link">Contact us</Button>
                </Link>
                <span>
                  <Button
                    variant="link"
                    onClick={() => {
                      handleMenuClose();
                      getAuthProvider()
                        .signOut()
                        .then(() => /*navigate("/")*/ (window.location.href = "/"));
                    }}
                  >
                    Logout
                  </Button>
                </span>
                {isAdmin && (
                  <>
                    <br />
                    <br />
                    <h4>Admin Area</h4>
                    <Link to="/event/search" onClick={handleMenuClose}>
                      <Button variant="link">Search for Events</Button>
                    </Link>
                    <Link to="/organisation/search" onClick={handleMenuClose}>
                      <Button variant="link">Search for Organisations</Button>
                    </Link>
                    <Link to="/user/search" onClick={handleMenuClose}>
                      <Button variant="link">Search for Users</Button>
                    </Link>
                  </>
                )}
              </Stack>
            </Offcanvas.Body>
          </Offcanvas>
        </Stack>
      </header>
      <Outlet />
      <DisplayToast />
    </div>
  );
};
