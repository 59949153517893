import Debug from "debug";
import * as React from "react";
import { FormControl, ListGroup, Spinner } from "react-bootstrap";
import { Entity, getRowKey, Option, Row } from "packages/gossamer-universal";
import { displayReduxTKError, setSearchQueryOptions, useEventListener, useQuery } from "../index";
import styles from "./AutoCompleter.module.css";

const debug = Debug("gfe/fi/AutoCompleter");

interface AutocompleterEntityProps<T extends Row> {
  className?: string;
  clearOnSelection?: boolean;
  entity: Entity<T>;
  excludeValues?: string[];
  isInvalid?: boolean;
  isValid?: boolean;
  onItemSelected: (newValue: string) => void;
  value: string;
}

export const AutoCompleterEntity = <T extends Row>(props: AutocompleterEntityProps<T>): JSX.Element => {
  const queryResp = useQuery(props.entity, {});
  const data: Option[] = React.useMemo(() => {
    const func: (row: T) => string =
      props.entity.frontEndOptions?.rowLabelAutocompleterItem ||
      props.entity.frontEndOptions?.rowLabelDynamic ||
      props.entity.rowLabel;
    const data = (queryResp.data || []).map((row) => {
      const id = getRowKey(props.entity, row);
      return {
        id,
        label: func(row),
        active: !props.excludeValues || props.excludeValues.indexOf(id) === -1,
      };
    });
    return data;
  }, [queryResp.data]);
  if (queryResp.isFetching) {
    return (
      <div>
        <Spinner animation="border" size="sm" />
      </div>
    );
  } else if (queryResp.error) {
    return <ListGroup.Item key="~error">{displayReduxTKError(queryResp.error)}</ListGroup.Item>;
  }
  return (
    <AutoCompleterOptions
      className={props.className}
      clearOnSelection={props.clearOnSelection}
      data={data}
      isInvalid={props.isInvalid}
      isValid={props.isValid}
      onItemSelected={props.onItemSelected}
      value={props.value}
    />
  );
};

interface AutoCompleterOptionsProps {
  className?: string;
  clearOnSelection?: boolean;
  data: Option[];
  isInvalid?: boolean;
  isValid?: boolean;
  onItemSelected: (newValue: string) => void;
  value?: string;
}

export const AutoCompleterOptions = (props: AutoCompleterOptionsProps) => {
  const [active, setActive] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const className = styles.AutoCompleter + (props.className ? " " + props.className : "");
  const setSearchTermToValue = () => {
    const newSearchTerm =
      (props.data.length > 0 && !!props.value && data.find((item) => item.id === props.value)?.label) || "";
    setSearchTerm(newSearchTerm);
  };
  React.useEffect(setSearchTermToValue, [props.data, props.value]);
  const data = props.data.filter(
    (item: Option) => item.active !== false && item.label.toLowerCase().indexOf((searchTerm || "").toLowerCase()) > -1
  );

  const onClose = () => {
    setSearchTermToValue();
    setActive(false);
  };
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debug(`onInputChange(${event.target.value || ""})`);
    setSearchTerm(event.target.value || "");
    setActive(event.target.value?.length > 2);
    if (event.target.value?.length === 0) {
      props.onItemSelected("");
    }
  };
  const onItemSelected = (newValue: string) => {
    debug(`onItemSelected(${newValue})`);
    setActive(false);
    props.onItemSelected(newValue);
  };

  return (
    <FormControl as="div" className={className} isInvalid={props.isInvalid} isValid={props.isValid}>
      <input
        type="text"
        onChange={onInputChange}
        value={searchTerm}
        autoComplete="off"
        placeholder="Type to search..."
      />
      {active && <AutoCompleterListGroup data={data} onClose={onClose} onItemSelected={onItemSelected} />}
    </FormControl>
  );
};

interface AutoCompleterListGroupProps {
  data: Option[];
  onClose: () => void;
  onItemSelected: (newValue: string) => void;
  value?: string;
}

export const AutoCompleterListGroup = (props: AutoCompleterListGroupProps): JSX.Element => {
  debug(`AutoCompleterListGroup`, props.data.length);
  const [highlightRow, setHighlightRow] = React.useState<number>();
  useEventListener(
    "keydown",
    React.useCallback(
      (event: KeyboardEvent) => {
        debug(`inside keyboard listener`);
        if (props.data?.length <= highlightRow) {
          setHighlightRow(undefined);
        }
        if (!props.data || props.data?.length === 0) {
          return;
        }
        if (event.key === "ArrowDown") {
          if (highlightRow === undefined || highlightRow < props.data?.length - 1) {
            setHighlightRow(highlightRow === undefined ? 0 : highlightRow + 1);
          }
        } else if (event.key === "ArrowUp") {
          if (highlightRow !== undefined) {
            setHighlightRow(highlightRow === 0 ? undefined : highlightRow - 1);
          }
        } else if (event.key === "Enter") {
          const item = props.data[highlightRow];
          debug(`calling onItemSelected`);
          props.onItemSelected(item.id);
        } else if (event.key === "Escape") {
          props.onClose();
        }
      },
      [highlightRow, props.data?.length]
    )
  );
  return (
    <ListGroup className={styles.AutoResults} variant="flush">
      {props.data.length === 0 && <ListGroup.Item key="~none">no matches</ListGroup.Item>}
      {props.data.map((item, index: number) => (
        <ListGroup.Item
          action
          active={item.id === props.value || index === highlightRow}
          key={item.id}
          onClick={props.onItemSelected.bind(null, item.id)}
        >
          {item.label}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};
