import * as React from "react";
import { Modal } from "react-bootstrap";
import { Entity, QueryOptions, ReferenceField } from "packages/gossamer-universal";
import { FormControl, QueryMulti, Search, UneditableReference, useQuery } from "../index";
import styles from "./RecordPicker.module.css";

interface RecordPickerProps {
  altText?: string; // to be shown if row is not supplied
  className?: string;
  clearOnSelection?: boolean;
  editable: boolean;
  field: ReferenceField;
  onItemSelected: (newValue: string) => void;
  queryOptions?: QueryOptions;
  row?: any;
  suppressLink?: boolean;
  validity?: boolean;
  value: string;
}

export const RecordPicker = (props: RecordPickerProps): JSX.Element => {
  const [showModal, setShowModal] = React.useState(false);
  const onRowClick = (key: string) => {
    setShowModal(false);
    props.onItemSelected(key);
  };
  const queryOptions = props.queryOptions ||
    props.field.queryOptions || {
      where: {
        and: props.field.refFilters || props.field.refEntity.defaultFilters || [],
      },
    };
  return (
    <FormControl className={props.className} flexDirection="row" validity={props.validity}>
      <button type="button" className="ControlIcon valueSelector" onClick={() => setShowModal(true)}>
        🔍
      </button>
      {!!props.altText && <div className={styles.RecordPickerLabel}>{props.altText}</div>}
      {!!props.value && !props.altText && (
        <div className={styles.ReferenceLabel}>
          <UneditableReference
            data={props.row}
            refEntity={props.field.refEntity}
            suppressLink={true}
            value={props.value}
          />
        </div>
      )}
      {!!props.value && (
        <button onClick={() => props.onItemSelected(null)} className="ControlIcon valueClearer">
          ✖
        </button>
      )}
      {showModal && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="ref-value-picker-modal-title"
          size="xl"
          fullscreen="xl-down"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ref-value-picker-modal-title">Select a {props.field.refEntity.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <QueryMulti
              entity={props.field.refEntity}
              render={Search}
              onRowClick={onRowClick}
              queryOptions={queryOptions}
            />
          </Modal.Body>
        </Modal>
      )}
    </FormControl>
  );
};

interface SearchQueryProps {
  entity: Entity<{}>;
  onRowClick: (key: string) => void;
  queryOptions: QueryOptions;
}

const SearchQuery = (props: SearchQueryProps) => {
  const [queryOptions, setQueryOptions] = useQueryOptionsChanges(props.entity, props.queryOptions);
  const gCacheQueryItem = useQuery(props.entity, queryOptions);
  return (
    <PaginatorAndListSettings
      entity={props.entity}
      gCacheQuery={gCacheQueryItem}
      queryOptions={queryOptions}
      render={(innerProps) => <SearchNew {...innerProps} onRowClick={props.onRowClick} />}
      setQueryOptions={setQueryOptions}
    />
  );
};
