import Debug from "debug";
import * as React from "react";
import { Form } from "react-final-form";
import { Alert, Modal } from "react-bootstrap";
import { FORM_ERROR } from "final-form";
import { useNavigate } from "react-router";
import {
  addRowToPayload,
  FinalButtons,
  FinalForm,
  getAuthProvider,
  getDataProvider,
  makeCompoundPayload,
} from "packages/gossamer-react-redux";
import { cloneEntity, createData, FieldType } from "packages/gossamer-universal";
import { fireEvent } from "packages/provider-gcp/Analytics";
import { User } from "model";

const debug = Debug("fe/user/SignUp");

export const SignUp = (): JSX.Element => {
  const navigate = useNavigate();
  const localUser = React.useMemo(() => {
    const localEntity = cloneEntity(User);
    localEntity.fields["confirmEmail"] = {
      seq: 6,
      type: FieldType.Text,
      label: "Confirm Email Address",
      fieldGroup: "main",
    };
    localEntity.fields["password"] = {
      seq: 6,
      type: FieldType.Password,
      label: "Password",
      fieldGroup: "main",
      mandatory: true,
    };
    // localEntity.fields.organisation.renderEditable = ReferenceInMemAutoComp;
    return localEntity;
  }, []);

  const onSubmit = async (values: any) => {
    try {
      const name = [values.firstName, values.lastName].join(" ");
      // would be nice to use applyPersistenceToPost here...
      const payload = makeCompoundPayload();
      addRowToPayload(payload, User, "C", values);
      await getAuthProvider().signUp(values.emailAddress, values.password, name);
      await getDataProvider().compound("/main/~/signup", payload);
      // auto-sign-in
      await getAuthProvider().signIn(values.emailAddress, values.password);
      fireEvent("signup", {
        name,
        emailAddress: values.emailAddress,
      });
      // wait for analytics call before unloading
      await new Promise((resolve) => setTimeout(resolve, 300));
      // navigate("/");
      window.location.href = "/";
    } catch (e: any) {
      console.error(e);
      return { [FORM_ERROR]: e.toString() };
    }
  };

  const onCancel = () => {
    navigate("/");
  };

  return (
    <Modal show={true} backdrop="static" centered={true} size="lg">
      <Form
        initialValues={createData(localUser)}
        onSubmit={onSubmit}
        validate={(values) => {
          const out: Record<string, string> = {};
          if (values.emailAddress !== values.confirmEmail) {
            out.confirmEmail = "you have not re-typed your email address exactly the same";
          }
          if (!values.organisation && !values.organisationFreeText) {
            out.organisationFreeText = "please select an organisation above or enter the name here";
          }
          // is this okay here?
          localUser.fields.organisationFreeText.visible = !values.organisation;
          return out;
        }}
        render={({ handleSubmit, submitError, submitting }) => {
          return (
            <>
              <Modal.Header>
                <Modal.Title>Signup</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {submitError && <Alert variant="danger">{submitError}</Alert>}
                <form onSubmit={handleSubmit}>
                  <FinalForm entity={localUser} fieldGroup="main" isCreate={true} />
                </form>
              </Modal.Body>
              <Modal.Footer>
                <FinalButtons cancel={onCancel} saveWithOutcome={() => handleSubmit()} submitting={submitting} />
              </Modal.Footer>
            </>
          );
        }}
      />
    </Modal>
  );
};
