import { Entity, QueryOptions, QueryResponse, Row } from "packages/gossamer-universal";
import { getDataProvider } from "../index";

export const useQuery = <T extends Row>(
  entity: Entity<T>,
  queryOptions: QueryOptions,
  skip?: boolean
): QueryResponse<T> => {
  return getDataProvider().useQuery(entity, queryOptions, skip);
};
