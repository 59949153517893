import {
  CompoundPayload,
  DataProvider,
  Entity,
  getRowKey,
  QueryOptions,
  QueryResponse,
  RecordResponse,
} from "packages/gossamer-universal";
import { FetchJson } from "./FetchJson";

let provider: DataProvider = null;

export const getDataProvider = () => {
  if (!provider) {
    throw new Error(`DataProvider has not been set`);
  }
  return provider;
};

export const setDataProvider = (arg: DataProvider) => {
  if (provider) {
    throw new Error(`DataProvider already set`);
  }
  provider = arg;
};

export const makeBasicDataProvider = (fetchJson: FetchJson): DataProvider => {
  const getQuery = <T extends {}>(entity: Entity<T>, queryOptions: QueryOptions): Promise<T[]> => {
    return fetchJson("GET", `/${entity.service}/${entity.id}?q=${encodeURIComponent(JSON.stringify(queryOptions))}`);
  };

  const useQuery = <T extends {}>(entity: Entity<T>, queryOptions: QueryOptions): QueryResponse<T> => {
    throw new Error("not implemented yet");
  };

  const getRecord = <T extends {}>(entity: Entity<T>, key: string): Promise<T> => {
    return fetchJson("GET", `/${entity.service}/${entity.id}/${encodeURIComponent(key)}`);
  };

  const useRecord = <T extends {}>(entity: Entity<T>, key: string): RecordResponse<T> => {
    throw new Error("not implemented yet");
  };

  const createRow = async <T>(entity: Entity<T>, payload: T) => {
    await fetchJson("POST", `/${entity.service}/${entity.id}`, payload);
  };

  const updateRow = async <T>(entity: Entity<T>, payload: T) => {
    const key = getRowKey(entity, payload);
    return fetchJson("PUT", `/${entity.service}/${entity.id}/${encodeURIComponent(key)}`, payload);
  };

  const deleteRow = async <T>(entity: Entity<T>, key: string) => {
    return fetchJson("DELETE", `/${entity.service}/${entity.id}/${encodeURIComponent(key)}`);
  };

  const compound = async <T>(compoundURL: string, payload: CompoundPayload) => {
    return fetchJson("POST", compoundURL, payload);
  };

  return {
    getQuery,
    useQuery,
    getRecord,
    useRecord,
    createRow,
    updateRow,
    deleteRow,
    compound,
  };
};
