import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import {
  Auth,
  cacheReducer,
  gCacheMiddleware,
  makeGCacheDataProvider,
  setAuthProvider,
  setDataProvider,
  setStore,
  transReducer,
  userReducer,
} from "packages/gossamer-react-redux";
import { makeFirebaseAuthProvider, makeFirestoreDataProvider } from "../packages/provider-gcp";
import { app } from "./Firebase";
import { LoggedInRoutes } from "./logged-in/Routes";
import { NotLoggedInRoutes } from "./not-logged-in/Routes";
import { eventMiddleware } from "./event/EventMiddleware";
import { userMiddleware } from "./user/UserMiddleware";
import "./Model"; // setReduxEntitites()
import { onAlreadySignedIn } from "./auth/Auth";
// import { setupRemoteLogging } from "./shared/RemoteLogging";

const analytics = getAnalytics(app);
const perf = getPerformance(app);

// Firebase auth
const authProvider = makeFirebaseAuthProvider(app);

setAuthProvider(authProvider);

// Data from Firestore
const sourceDataProvider = makeFirestoreDataProvider(app);

const store = configureStore({
  reducer: {
    cache: cacheReducer,
    trans: transReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([eventMiddleware, userMiddleware, gCacheMiddleware(sourceDataProvider)]),
});
setStore(store);
setDataProvider(makeGCacheDataProvider(store, sourceDataProvider));

// setupRemoteLogging();

const App = (): JSX.Element => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <Auth
          loggedInRoutes={LoggedInRoutes}
          notLoggedInRoutes={NotLoggedInRoutes}
          onAlreadySignedIn={onAlreadySignedIn}
        />
      </Provider>
    </React.StrictMode>
  );
};

const target = document.querySelector("#app");
ReactDOM.render(<App />, target);
