import * as React from "react";
import { useSelector } from "react-redux";
import {
  getConfig,
  InMemoryTextSearch,
  PageProps,
  PageTitle,
  Section,
  selectUser,
} from "packages/gossamer-react-redux";
import { Event, EventRow, OrganisationRow, ReservationRow } from "model";
import { EventTile } from "./EventTile";
import { EventTileContainer } from "./EventTileContainer";
import { InMemoryPaginator } from "packages/gossamer-react-redux/data/InMemoryPaginator";
import { useLoadData } from "front-end/shared/useLoadData";
import { Spinner } from "react-bootstrap";
import { QueryResponse } from "packages/gossamer-universal";

export const EventsOnDemand = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  const user = useSelector(selectUser)();
  const { eventResp, reservationResp, organisationResp, isError, isFetching, isReady } = useLoadData(user.userId);

  return (
    <InnerFrame>
      <div style={{ height: 70 }} />
      <PageTitle title="On-Demand Training" subtitle="Online training that can be started whenever you like" />
      <Section>
        {isError && <p>An error occurred loading data, please try again later</p>}
        {isFetching && <Spinner animation="border" size="sm" />}
        {isReady && (
          <EventsOnDemandInner
            eventResp={eventResp}
            organisationResp={organisationResp}
            reservationResp={reservationResp}
          />
        )}
      </Section>
    </InnerFrame>
  );
};

interface EventsOnDemandInnerProps {
  eventResp: QueryResponse<EventRow>;
  organisationResp: QueryResponse<OrganisationRow>;
  reservationResp: QueryResponse<ReservationRow>;
}

const EventsOnDemandInner = (props: EventsOnDemandInnerProps) => {
  const outerFilter = (events: EventRow[]) =>
    events.filter(
      (row) =>
        (row.visibility === "ANYONE" || row.organisation === getConfig("org-path")) &&
        row.distributionType === "ON-DEMAND"
    );

  return (
    <InMemoryTextSearch
      entity={Event}
      orgData={props.organisationResp.data}
      rows={outerFilter(props.eventResp.data)}
      searchFields={["title", "location", "description", "type", "organisationName"]}
      render={(innerProps) => (
        <InMemoryPaginator
          pageSize={10}
          rows={outerFilter(innerProps.rows)}
          render={(innerProps) => {
            const children = innerProps.rows.map((eventRow) => <EventTile eventRow={eventRow} key={eventRow.id} />);
            return <EventTileContainer>{children}</EventTileContainer>;
          }}
        />
      )}
    />
  );
};
