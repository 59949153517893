import * as React from "react";
import { Button, Spinner } from "react-bootstrap";
import { TransButtonsOptions } from "../data/TransButtons";

export interface FinalButtonsProps {
  saveWithOutcome: (outcomeId: string) => void;
  cancel: () => void;
  options?: TransButtonsOptions;
  submitting: boolean;
}

export const FinalButtons = (props: FinalButtonsProps): JSX.Element => {
  const outcomes = props.options?.outcomes || [
    {
      id: "save",
      label: "Save",
      nextTasks: [],
      default: true,
    },
  ];

  return (
    <div aria-label="transaction buttons" className="gap10px">
      {outcomes.map((outcome) => (
        <Button
          key={outcome.id}
          onClick={() => props.saveWithOutcome(outcome.id)}
          type={outcome.default ? "submit" : "button"}
          variant={outcome.variant || (outcome.default ? "primary" : "secondary")}
          disabled={props.submitting}
        >
          {props.submitting && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
          {!props.submitting && outcome.label}
        </Button>
      ))}
      {!!props.cancel && (
        <Button onClick={props.cancel} variant="secondary" disabled={props.submitting}>
          {props.submitting && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
          {!props.submitting && "Cancel"}
        </Button>
      )}
    </div>
  );
};
