import * as React from "react";
import { Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Outcome } from "packages/gossamer-universal";
import { selectTrans, Transaction } from "../index";

export interface TransButtonsProps {
  trans: Transaction;
  saveWithOutcome: (outcomeId: string) => void;
  cancel: () => void;
  options?: TransButtonsOptions;
}

export interface TransButtonsOptions {
  outcomes?: Outcome[];
}

export const TransButtons = (props: TransButtonsProps): JSX.Element => {
  const reduxTrans = useSelector(selectTrans)(props.trans?.getTransId());
  const inProgress = !reduxTrans || reduxTrans.isSaving;
  const outcomes = props.options?.outcomes || [
    {
      id: "save",
      label: "Save",
      nextTasks: [],
    },
  ];

  return (
    <div aria-label="transaction buttons" className="gap10px">
      {outcomes.map((outcome) => (
        <Button
          key={outcome.id}
          onClick={() => props.saveWithOutcome(outcome.id)}
          variant={outcome.variant || "primary"}
          disabled={inProgress}
        >
          {inProgress && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
          {!inProgress && outcome.label}
        </Button>
      ))}
      {!!props.cancel && (
        <Button onClick={props.cancel} variant="secondary" disabled={inProgress}>
          {inProgress && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
          {!inProgress && "Cancel"}
        </Button>
      )}
    </div>
  );
};
