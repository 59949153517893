import * as React from "react";
import { selectField, Transaction } from "packages/gossamer-react-redux";
import { Field, OptionsField } from "packages/gossamer-universal";
import { PointsOption, User } from "../../model";
import { FixedSubLevel } from "front-end/shared/FixedSubLevel";
import { useSelector } from "react-redux";

interface CurrentPointsProps {
  category: "medical" | "surgical";
  transaction: Transaction;
}

const getMaxPoints = (field: Field) => {
  const options = (field as OptionsField).options || [];
  return options.reduce((prev, curr) => Math.max(prev, (curr as PointsOption).points || 0), 0);
};

export const CurrentPoints = (props: CurrentPointsProps): JSX.Element => {
  const total = Object.values(User.fields).reduce((prev: number, curr: Field) => {
    return prev + ((curr.fieldGroup === props.category && getMaxPoints(curr)) || 0);
  }, 0);
  const curr = useSelector(selectField)(props.transaction?.getTransId(), 0, props.category + "Points")?.currValue;
  return (
    <FixedSubLevel>
      <div>
        {props.category.substring(0, 1).toUpperCase()}
        {props.category.substring(1)} Points&nbsp;&nbsp;
        <b>{curr}</b>&nbsp;&nbsp;/&nbsp;&nbsp;
        <b>{total}</b>
      </div>
    </FixedSubLevel>
  );
};
