import Debug from "debug";
import { AnyAction, Store } from "redux";
import { useDispatch, useSelector } from "react-redux";
import {
  Entity,
  DataProvider,
  QueryOptions,
  QueryResponse,
  doFrontEndEvaluates,
  RecordResponse,
  CompoundPayload,
  getRowKey,
} from "packages/gossamer-universal";
import { getEntity } from "../index";
import {
  clearAllQueriesInGCache,
  clearSingleRecordInGCache,
  GCacheState,
  cacheSweep,
  // getGCacheEntity,
  getQueryFromGCache,
  getRecordFromGCache,
  GCacheQuery,
  GCacheRecord,
  selectQueryFromGCache,
  selectRecordFromGCache,
} from "./ReduxGCache";

const debug = Debug("gfe/da/ReduxGCacheDataProvider");

type GCacheStore = Store<{ cache: GCacheState }, AnyAction>;

export const makeGCacheDataProvider = (
  store: GCacheStore,
  sourceDataProvider: DataProvider,
  gCacheSweepIntervalMillis?: number
): DataProvider => {
  const getQuery = <T extends {}>(entity: Entity<T>, queryOptions: QueryOptions): Promise<T[]> => {
    return sourceDataProvider.getQuery(entity, queryOptions);
  };

  const useQuery = <T extends {}>(entity: Entity<T>, queryOptions: QueryOptions, skip?: boolean): QueryResponse<T> => {
    const dispatch = useDispatch();
    const defaultedQueryOptions = queryOptions || {};
    defaultedQueryOptions.where = defaultedQueryOptions.where || {};
    const queryOptionsStr = JSON.stringify(queryOptions);
    // debug(`ReduxGCacheDataProvider.useQuery dispatching ${getQueryFromGCache.type}`);
    if (!skip) {
      dispatch(
        getQueryFromGCache({
          entity,
          query: queryOptions,
        })
      );
    }
    const cacheItem = useSelector(selectQueryFromGCache)(entity.id, queryOptionsStr) as GCacheQuery<T>;
    if (cacheItem.data && !cacheItem.frontEndEvaluatesApplied) {
      cacheItem.data = cacheItem.data?.map((row) => doFrontEndEvaluates(entity, row) as T);
      cacheItem.frontEndEvaluatesApplied = true;
    }
    // debug(`data: ${cacheItem.data?.length}, ${entity.id}, ${queryOptionsStr}`);
    return cacheItem;
  };

  const getRecord = <T extends {}>(entity: Entity<T>, key: string): Promise<T> => {
    return sourceDataProvider.getRecord(entity, key);
  };

  const useRecord = <T extends {}>(entity: Entity<T>, key: string, skip?: boolean): RecordResponse<T> => {
    const dispatch = useDispatch();
    if (!skip && !!key) {
      dispatch(
        getRecordFromGCache({
          entity,
          key,
        })
      );
    }
    const cacheItem = useSelector(selectRecordFromGCache)(entity.id, key) as GCacheRecord<T>;
    if (cacheItem.data && !cacheItem.frontEndEvaluatesApplied) {
      cacheItem.data = doFrontEndEvaluates(entity, cacheItem.data);
      cacheItem.frontEndEvaluatesApplied = true;
    }
    // debug(`data: ${cacheItem.data}, ${entity.id}, ${key}`);
    return cacheItem;
  };

  const createRow = async <T>(entity: Entity<T>, payload: T) => {
    await sourceDataProvider.createRow(entity, payload);
    resetCache(entity.id);
  };

  const updateRow = async <T>(entity: Entity<T>, payload: T) => {
    const key = getRowKey(entity, payload);
    const resp = await sourceDataProvider.updateRow(entity, payload);
    resetCache(entity.id, key);
    return resp;
  };

  const deleteRow = async <T>(entity: Entity<T>, key: string) => {
    await sourceDataProvider.deleteRow(entity, key);
    resetCache(entity.id);
  };

  const compound = async <T>(compoundURL: string, payload: CompoundPayload) => {
    const resp = sourceDataProvider.compound(compoundURL, payload);
    payload.rows.forEach((row) =>
      resetCache(row.entityId, row.action === "U" ? getRowKey(getEntity(row.entityId), row.data) : undefined)
    );
    return resp;
  };

  const resetCache = (entityId: string, updateKey?: string): void => {
    store.dispatch(
      clearAllQueriesInGCache({
        entityId,
      })
    );
    if (updateKey) {
      store.dispatch(
        clearSingleRecordInGCache({
          entityId,
          key: updateKey,
        })
      );
    }
  };

  if (typeof gCacheSweepIntervalMillis === "number" && gCacheSweepIntervalMillis > 0) {
    setInterval(() => {
      store.dispatch(cacheSweep({}));
    }, gCacheSweepIntervalMillis);
  }

  return {
    getQuery,
    useQuery,
    getRecord,
    useRecord,
    createRow,
    updateRow,
    deleteRow,
    compound,
  };
};
