import React from "react";
import { Spinner } from "react-bootstrap";
import { DataResponse } from "packages/gossamer-universal";

interface ParallelLoadProps {
  children: React.ReactNode;
  responses: DataResponse[];
}

export const ParallelLoad = (props: ParallelLoadProps) => {
  const isLoading = props.responses.reduce((prev, response) => prev || response.isFetching, false);
  const errors = props.responses.map((response) => response.error).filter((error) => !!error);
  if (isLoading) {
    return <Spinner />;
  }
  if (errors.length > 0) {
    errors.forEach(console.error);
    return <div>An error occurred loading data</div>;
  }
  return props.children;
};
