import Debug from "debug";
import * as React from "react";
import { Alert, Button, Form, FormControl, FormGroup, FormLabel, Modal, Spinner, Stack } from "react-bootstrap";
import { getAuthProvider, NotifyInfo } from "../index";

const debug = Debug("gfe/au/ForgotPasswordSend");

export const ForgotPasswordSend = (): JSX.Element => {
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<NotifyInfo>(null);
  const emailRef = React.useRef<HTMLInputElement>();
  const handleFormSubmit = async (event) => {
    try {
      setMessage(null);
      setLoading(true);
      debug(`calling initiatePasswordReset(${emailRef.current.value})`);
      await getAuthProvider().passwordResetInitiate(emailRef.current.value);
      setMessage(["email sent", "primary"]);
    } catch (error) {
      console.error("inside ForgotPasswordSend", error.message);
      setMessage([error.message, "success"]);
    }
    setLoading(false);
    event.preventDefault();
  };

  return (
    <Modal show={true} backdrop="static" centered={true}>
      <Modal.Header>
        <Modal.Title>Forgotten password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Stack gap={3}>
            {message && <Alert variant={message[1]}>{message[0]}</Alert>}
            <FormGroup controlId="email">
              <FormLabel>
                Please enter your email address here and if you have have an account we'll send you an email with a link
                to allow you to reset your password
              </FormLabel>
              <FormControl type="text" ref={emailRef} autoComplete="username" />
            </FormGroup>
            <Stack
              direction="horizontal"
              style={{
                justifyContent: "flex-end",
              }}
            >
              <Button variant="link" href="/signin">
                Back to log-in
              </Button>
              <Button variant="primary" onClick={handleFormSubmit}>
                {isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                {!isLoading && "Send reset email"}
              </Button>
            </Stack>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
