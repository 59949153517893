import * as React from "react";
import { Spinner } from "react-bootstrap";
import { DataVolume, getRowKey, Option, QueryOptions, ReferenceField } from "packages/gossamer-universal";
import { displayReduxTKError, Dropdown, RecordPicker, UneditableReference, useRecord, useQuery } from "../index";

export interface ReferenceProps {
  className?: string;
  clearOnSelection?: boolean;
  editable: boolean;
  field: ReferenceField;
  onItemSelected: (newValue: string) => void;
  suppressLink?: boolean;
  validity?: boolean;
  value: string;
}

interface SmallTableProps extends ReferenceProps {
  rows: any[];
}

export const ReferenceEditableSmallTable = (props: SmallTableProps): JSX.Element => {
  const options: Option[] = props.rows.map((row) => {
    const key = getRowKey(props.field.refEntity, row);
    const active = !props.field.excludeValues || props.field.excludeValues.indexOf(key) === -1;
    return {
      id: key,
      label: (props.field.refEntity.frontEndOptions?.rowLabelDropdownItem || props.field.refEntity.rowLabel)(row),
      active,
    };
  });
  return (
    <Dropdown
      className={props.className}
      clearOnSelection={props.clearOnSelection}
      options={options}
      onItemSelected={props.onItemSelected}
      mandatory={props.field.mandatory}
      validity={props.validity}
      value={props.value}
    />
  );
};

export const ReferenceUneditableSmallTable = (props: SmallTableProps): JSX.Element => {
  const refEntity = props.field.refEntity;
  let data = null;
  if (props.value) {
    data = props.rows.find((row) => getRowKey(refEntity, row) === String(props.value));
  }
  return (
    <UneditableReference
      data={data}
      refEntity={props.field.refEntity}
      suppressLink={props.suppressLink}
      value={props.value}
    />
  );
};

export const ReferenceSmallTable = (props: ReferenceProps): JSX.Element => {
  const queryOpts: QueryOptions = {
    limit: 100,
    order: props.field.refEntity.defaultSort || [],
  };
  if (props.field.refFilters) {
    queryOpts.where = {
      and: props.field.refFilters,
    };
  }
  const { data, error, isFetching } = useQuery(props.field.refEntity, queryOpts);
  return (
    <>
      {isFetching && <Spinner animation="border" size="sm" />}
      {error && <span>{displayReduxTKError(error)}</span>}
      {!!data && props.editable && <ReferenceEditableSmallTable {...props} rows={data} />}
      {!!data && !props.editable && <ReferenceUneditableSmallTable {...props} rows={data} />}
    </>
  );
};

export const ReferenceNonSmallTableNonBlank = (props: ReferenceProps): JSX.Element => {
  console.log(`ReferenceNonSmallTableNonBlank calling useRecord(${props.value})`);
  const { data, error, isFetching } = useRecord(props.field.refEntity, props.value);
  const errorText = !!error && displayReduxTKError(error) + ": " + props.value;
  return (
    <>
      {isFetching && <Spinner animation="border" size="sm" />}
      {error && props.editable && <RecordPicker {...props} altText={errorText} />}
      {error && !props.editable && <span>{errorText}</span>}
      {!isFetching && !error && props.editable && <RecordPicker {...props} row={data} />}
      {!isFetching && !error && !props.editable && !!props.value && (
        <UneditableReference
          data={data}
          refEntity={props.field.refEntity}
          suppressLink={props.suppressLink}
          value={props.value}
        />
      )}
    </>
  );
};

export const Reference = (props: ReferenceProps): JSX.Element => {
  const skipLookup =
    props.field.refEntity.frontEndOptions?.skipReferenceLookup &&
    props.field.refEntity.frontEndOptions?.skipReferenceLookup(props.value);

  if (props.field.refEntity.dataVolume === DataVolume.Small) {
    if (props.editable || !!props.value) {
      return <ReferenceSmallTable {...props} />;
    } else {
      return null;
    }
  } else if (!!props.value) {
    if (!skipLookup) {
      return <ReferenceNonSmallTableNonBlank {...props} />;
    } else if (props.editable) {
      return <RecordPicker {...props} altText={props.value} />;
    } else {
      return <span>{props.value}</span>;
    }
  } else if (props.editable) {
    return <RecordPicker {...props} />;
  } else {
    return null;
  }
};
