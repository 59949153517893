import * as React from "react";
import { Alert, Button, Stack } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Event, EventRow, Reservation, User, UserRow } from "model";
import {
  Display,
  DynamicText,
  getConfig,
  IsAllowed,
  PageProps,
  QueryMulti,
  QuerySingle,
  Section,
  useDocumentTitle,
} from "packages/gossamer-react-redux";
import { getPoints } from "front-end/shared/Points";
import { cloneEntity, getFilter } from "packages/gossamer-universal";
import { EventStrip } from "./EventStrip";
import { toLocaleDateTimeToMinutes } from "front-end/shared/Helpers";
import { useGetEventImageSrc } from "./useGetEventImageSrc";
import { InMemoryPaginator } from "packages/gossamer-react-redux/data/InMemoryPaginator";

export const EventDisplay = (props: PageProps): JSX.Element => {
  const params = useParams();
  const InnerFrame = props.innerFrame;

  return (
    <InnerFrame>
      <QuerySingle
        entity={Event}
        keyValue={params.key}
        render={(queryProps) => <EventDisplayInner eventRow={queryProps.row} />}
      />
    </InnerFrame>
  );
};

interface PointsSummaryProps {
  eventRow: EventRow;
  userRow: UserRow;
}

export const PointsSummary = (props: PointsSummaryProps): JSX.Element => {
  const elements = getPoints(props.eventRow, props.userRow);
  const children = elements
    .filter((item) => item.maxPoints > 0)
    .map((item) => (
      <tr key={item.text}>
        <td>{item.text}</td>
        <td style={{ textAlign: "right" }}>{item.maxPoints}</td>
        <td style={{ textAlign: "right" }}>{item.userPoints}</td>
      </tr>
    ));

  const eventPoints = elements.reduce((prev, curr) => prev + curr.maxPoints, 0);
  const netPoints = elements.reduce((prev, curr) => prev + Math.max(curr.maxPoints - curr.userPoints, 0), 0);
  if (children.length === 0) {
    children.push(
      <tr key="~none">
        <td>none</td>
      </tr>
    );
  } else {
    children.push(
      <tr key="~total">
        <td style={{ textAlign: "right" }}>total</td>
        <td style={{ textAlign: "right" }}>{eventPoints}</td>
      </tr>
    );
  }
  return (
    <>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th></th>
            <th style={{ textAlign: "right" }}>On Offer with this Event</th>
            <th style={{ textAlign: "right" }}>You Already Have</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
      <br />
      {netPoints === 0 && <p>There are no additional points available to you from attending this event</p>}
      {netPoints > 0 && (
        <p>
          You could gain up to <b>{netPoints}</b> additional point{netPoints === 1 ? "" : "s"} from attending this event
        </p>
      )}
    </>
  );
};

interface EventDisplayInnerProps {
  eventRow: EventRow;
}

const EventDisplayInner = (props: EventDisplayInnerProps): JSX.Element => {
  const src = useGetEventImageSrc(props.eventRow);
  const title = props.eventRow.title;
  useDocumentTitle(title);

  const userId = getConfig("user-id");
  const userIsOrganiser = props.eventRow.organiser === userId;
  const localEvent = cloneEntity(Event);
  localEvent.fields.title.visible = false;
  localEvent.fields.image.visible = false;
  localEvent.fields.description.visible = false;
  if (!userIsOrganiser) {
    localEvent.fields.visibility.visible = false;
    localEvent.fields.bookingMethod.visible = false;
    localEvent.fields.bookingUrl.visible = false;
    localEvent.fields.capacity.visible = false;
    localEvent.fields.acceptCoTutorOffers.visible = false;
  }
  return (
    <>
      <Section>
        {props.eventRow.visibility === "THIS-ORG-ONLY" && props.eventRow.organisation && (
          <Alert variant="warning">
            This event is only available to members of{" "}
            <DynamicText text={`<lookup:Organisation:${props.eventRow.organisation}>`} />
          </Alert>
        )}
        <div>
          <img src={src} style={{ width: "100%" }} />
        </div>
        <br />
        <QueryMulti
          entity={Reservation}
          queryOptions={{
            where: {
              and: [getFilter("userId", "eq", userId), getFilter("eventId", "eq", props.eventRow.id)],
            },
          }}
          showItemCount={false}
          showPaginator="never"
          showSettingsControl={false}
          render={(innerProps) => <EventStrip eventRow={props.eventRow} existingReservationRow={innerProps.rows[0]} />}
        />
        {props.eventRow.acceptCoTutorOffers && !!userId && (
          <div>
            <Button
              size="sm"
              variant="primary"
              href={`mailto:${props.eventRow.organiser}?subject=Co-tutor Offer: ${
                props.eventRow.title
              }, ${toLocaleDateTimeToMinutes(props.eventRow.startsAt)}`}
            >
              Offer to be Co-tutor
            </Button>
          </div>
        )}
        <br />
        <h1>{title}</h1>
        <p style={{ whiteSpace: "pre-wrap" }}>{props.eventRow.description}</p>
        <Display entity={localEvent} row={props.eventRow} keyValue={props.eventRow.id} />
        <Stack gap={3} direction="horizontal">
          <IsAllowed entity={Event} action="update" row={props.eventRow}>
            <Link to={`/event/update/${props.eventRow.id}`}>
              <Button>Update</Button>
            </Link>
            <Link to={`/event/copy/${props.eventRow.id}`}>
              <Button>Copy</Button>
            </Link>
          </IsAllowed>
          <IsAllowed entity={Event} action="delete" row={props.eventRow}>
            <Link to={`/event/delete/${props.eventRow.id}`}>
              <Button variant="danger">Delete</Button>
            </Link>
          </IsAllowed>
        </Stack>
      </Section>
      {!userIsOrganiser && (
        <Section title="Points" subtitle="Points potentially available with this Event">
          <QuerySingle
            entity={User}
            keyValue={getConfig("user-id")}
            render={(queryProps) => <PointsSummary eventRow={props.eventRow} userRow={queryProps.row} />}
          />
        </Section>
      )}
      {userIsOrganiser && props.eventRow.bookingMethod === "IN-CAREERBOOK" && (
        <Section title="Bookings" subtitle="Users that have booked a place for this Event">
          <QueryMulti
            entity={Reservation}
            alterEntity={(localEntity: typeof Reservation) => {
              localEntity.fields.eventId.listColumn = false;
            }}
            queryOptions={{
              where: {
                and: [getFilter("eventId", "eq", props.eventRow.id)],
              },
              limit: 1000,
            }}
            render={(innerProps) => (
              <InMemoryPaginator
                pageSize={20}
                rows={innerProps.rows}
                render={({ rows }) => (
                  <>
                    {rows.map((row) => (
                      <div key={row.userId}>{row.userId}</div>
                    ))}
                  </>
                )}
              />
            )}
            showItemCount={false}
            showSettingsControl={false}
          />
        </Section>
      )}
    </>
  );
};
