import Debug from "debug";
import * as React from "react";
import { Col as BCol, Row as BRow, FormGroup, FormLabel } from "react-bootstrap";
import { Entity, Field, getFieldArray, getFieldColumnWidths, isFieldEditable, Row } from "packages/gossamer-universal";
import { ErrorBoundary, FinalFieldGeneral } from "../index";
import styles from "../sections/FieldBlock.module.css";

const debug = Debug("gfe/se/FinalForm");

interface FinalFormProps<T extends Row> {
  alias?: string;
  entity: Entity<T>;
  fieldGroup?: string;
  isCreate: boolean;
}

export const FinalForm = <T extends {}>(props: FinalFormProps<T>): JSX.Element => {
  const identifier = props.alias || props.entity.id;
  return (
    <ErrorBoundary>
      <BRow className="mb20px">
        {getFieldArray(props.entity)
          .map(([field, fieldId]: [Field, string]) => [
            field,
            fieldId,
            isFieldEditable(field, props.isCreate ? "C" : "U"),
          ])
          .filter(([field, _, editable]: [Field, string, boolean]) => {
            const fieldGroupCheck = !props.fieldGroup || props.fieldGroup === field.fieldGroup;
            const visibleAttrCheck =
              typeof field.visible === "boolean"
                ? field.visible
                : editable; /* TODO || props.row.getFieldValue(fieldId) */
            return fieldGroupCheck && visibleAttrCheck;
          })
          .map(([field, fieldId, editable]: [Field, string, boolean]) => {
            return (
              <BCol key={fieldId} {...getFieldColumnWidths(field)}>
                <FinalFieldBlock editable={editable} field={field} fullId={[identifier, fieldId].join(".")} />
              </BCol>
            );
          })}
      </BRow>
    </ErrorBoundary>
  );
};

interface FinalFieldBlockProps {
  editable: boolean;
  field: Field;
  fullId: string;
}

export const FinalFieldBlock = (props: FinalFieldBlockProps) => (
  <FormGroup id={props.fullId} controlId={props.fullId} className={`${styles.FieldBlock} fieldBlock`}>
    <FormLabel>
      {props.field.label}
      {props.field.mandatory && props.editable ? " *" : ""}
      {props.field.description && (
        <>
          &nbsp;&nbsp;<a title={props.field.description}>ⓘ</a>
        </>
      )}
    </FormLabel>
    <FinalFieldGeneral editable={props.editable} field={props.field} fullId={props.fullId} />
  </FormGroup>
);
