import { useQuery } from "packages/gossamer-react-redux";
import { Event, Organisation, Reservation } from "model";
import { getFilter, getISODateString } from "packages/gossamer-universal";

export const useLoadData = (userId?: string) => {
  const eventResp = useQuery(Event, {
    where: {
      and: [
        {
          or: [
            getFilter("startsAt", "gt", getISODateString(new Date())),
            getFilter("distributionType", "eq", "ON-DEMAND"),
          ],
        },
        getFilter("visibility", "ne", "HIDDEN"),
        // {
        //   or: [getFilter("visibility", "eq", "ANYONE"), getFilter("organisation", "eq", getConfig("org-path"))],
        // },
      ],
    },
  });

  const reservationResp = useQuery(
    Reservation,
    {
      where: {
        and: [getFilter("userId", "eq", userId), getFilter("unreservedAt", "bl", null)],
      },
    },
    !userId // skip if no user id
  );

  const organisationResp = useQuery(Organisation, {});

  const isFetching = eventResp.isFetching || (!!userId && reservationResp.isFetching) || organisationResp.isFetching;
  const isError = eventResp.error || reservationResp.error || organisationResp.error;
  if (isError) {
    console.error(eventResp.error);
    console.error(reservationResp.error);
    console.error(organisationResp.error);
  }

  return {
    eventResp,
    reservationResp,
    organisationResp,
    isError,
    isFetching,
    isReady: !isFetching && !isError,
  };
};
