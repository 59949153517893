import Debug from "debug";
import { createAction, createReducer } from "@reduxjs/toolkit";
import { Entity, getMatchingRule, Row, SecurityAction } from "packages/gossamer-universal";
import { getStore, setConfig } from "../index";

const debug = Debug("gfe/da/ReduxUser");

export interface UserState {
  userId: string;
  roleId: string;
  orgPath: string;
  name: string;
  emailAddress: string;
}

const INITIAL_STATE: UserState = {
  userId: null,
  roleId: null,
  orgPath: null,
  name: null,
  emailAddress: null,
};

const getNameFromEmailOrUserId = (email: string | null, userId: string | null) =>
  email ? email.substring(0, email.indexOf("@")) : userId || "no email or user id";

const signin = createAction<UserState>("SIGNIN");

const signout = createAction<{}>("SIGNOUT");

export const userReducer = createReducer(INITIAL_STATE, (builder) => {
  builder

    .addCase(signin, (state, action) => {
      console.log(`inside signin reducer`);
      state.orgPath = action.payload.orgPath;
      state.roleId = action.payload.roleId;
      state.userId = action.payload.userId;
      state.name = action.payload.name || getNameFromEmailOrUserId(action.payload.emailAddress, action.payload.userId);
      state.emailAddress = action.payload.emailAddress;
      console.log(`inside signin reducer end`);
    })

    .addCase(signout, (state, action) => {
      Object.keys(INITIAL_STATE).forEach((key) => (state[key] = INITIAL_STATE[key]));
    });
});

// Selectors
export const selectUser = (state: { user: UserState }) => () => state.user;

export const selectIsSignedIn = (state: { user: UserState }) => () => !!state.user.userId;

export const selectIsAdmin = (state: { user: UserState }) => () => state.user.roleId === "admin";

export const isAllowed = <T extends Row>(
  entity: Entity<{}>,
  action: SecurityAction,
  user: UserState,
  special?: string,
  row?: T
): boolean => {
  const rule = getMatchingRule(
    entity,
    { userId: user.userId, roleId: user.roleId, orgPath: user.orgPath },
    action,
    special,
    row
  );
  return !!rule && rule.access !== "none";
};

// move elsewhere and pass the store in as an arg?
export const handleSignIn = (
  userId: string,
  roleId: string | null,
  orgPath: string | null,
  name: string | null,
  emailAddress: string | null
) => {
  const loginPayload = {
    userId,
    roleId,
    orgPath,
    emailAddress,
    name,
  };
  debug("ReduxUser.handleSignIn() login payload:", loginPayload);
  // call setConfig() first so these are set by the time signin is dispatched
  // putting them after the dispatch caused a race condition whereby getConfig("user-id")
  // was returning null in signed-in routes
  setConfig("user-id", userId);
  setConfig("role-id", roleId);
  setConfig("org-path", orgPath);
  getStore().dispatch(signin(loginPayload));
  debug("ReduxUser.handleSignIn() back");
};

export const handleSignOut = () => {
  setConfig("user-id", null);
  setConfig("role-id", null);
  setConfig("org-path", null);
  getStore().dispatch(signout({}));
};
