import { Row } from "../types/core";
import { Entity } from "../types/entity";
import { SecurityAction, SecurityContext, SecurityRule, SecurityRuleWithSpecialAction } from "../types/security";

export const getMatchingRule = <T extends Row>(
  entity: Entity<T>,
  sctx: SecurityContext,
  action: SecurityAction,
  specialAction: string
): SecurityRule | undefined => {
  const isWrite = ["create", "update", "delete"].indexOf(action) > -1;
  return entity.security.find((rule) => {
    return (
      (rule.role === sctx.roleId || rule.role === "all") &&
      (action !== "special" || specialAction === (rule as SecurityRuleWithSpecialAction).special) &&
      (rule.action === action || rule.action === "all" || (rule.action === "write" && isWrite))
    );
  });
};
