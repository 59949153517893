import Debug from "debug";
import * as React from "react";
import { useNavigate } from "react-router";
import { notify, Transaction } from "../index";

const debug = Debug("gfe/da/useTrans");

export interface UseTransArgs {
  followOnURL?: string;
  compoundURL?: string;
  changeFollowOnURLOnSuccess?: (resp: any) => string;
  onSuccessfulSave?: (resp: any) => void;
  allowSaveWhenUnmodified?: boolean;
  sendUnmodifiedRows?: boolean;
}

export interface UseTransResult {
  cancel: () => void;
  save: () => Promise<boolean>;
  saveWithOutcome: (outcomeId: string) => Promise<boolean>;
  trans: Transaction;
}

export const useTrans: (args: UseTransArgs) => UseTransResult = ({
  followOnURL,
  compoundURL,
  changeFollowOnURLOnSuccess,
  onSuccessfulSave,
  sendUnmodifiedRows = false,
  allowSaveWhenUnmodified = false,
}) => {
  const [trans, setTrans] = React.useState<Transaction>(null);

  React.useEffect(() => {
    debug(`inside useEffect() making transaction`);
    setTrans(new Transaction(compoundURL, sendUnmodifiedRows, allowSaveWhenUnmodified));
  }, []);
  // debug(`outside useMemo() transaction: ${trans.getTransId()}`);
  const navigate = useNavigate();

  const saveWithOutcome = async (outcomeId: string) => {
    try {
      const result = await trans.save(outcomeId);
      debug(result);
      notify(["saved", "success"]);
      if (onSuccessfulSave) {
        onSuccessfulSave(result);
      }
      if (changeFollowOnURLOnSuccess) {
        navigate(changeFollowOnURLOnSuccess(result));
      } else if (followOnURL) {
        followOn();
      }
      trans.postSave();
      return true;
    } catch (errors) {
      debug(errors);
      const msgToString = (e: any): string => {
        return typeof e.error === "string"
          ? e.error
          : typeof e.message === "string"
          ? e.message
          : e.getMessage
          ? e.getMessage()
          : e.toString();
      };
      const msg = Array.isArray(errors) ? errors.map(msgToString).join(", ") : msgToString(errors);
      notify([msg, "danger"]);
      return false;
    }
  };

  const save = () => saveWithOutcome("save");

  const cancel = followOnURL
    ? () => {
        followOn();
        trans.cancel();
      }
    : null;

  const followOn = () => navigate(followOnURL);

  return { cancel, save, saveWithOutcome, trans };
};
