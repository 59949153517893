import * as React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IsAllowed, PageProps, PageTitle, QueryMulti, Section, selectUser } from "packages/gossamer-react-redux";
import { Event } from "model";
import { EventTile } from "./EventTile";
import { EventTileContainer } from "../event/EventTileContainer";
import { getFilter, getISODateString } from "packages/gossamer-universal";

export const EventsOrganising = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  const user = useSelector(selectUser)();
  return (
    <InnerFrame>
      <PageTitle title="Events I am Organising" subtitle="Events I have created" />
      <Section>
        <QueryMulti
          entity={Event}
          queryOptions={{
            where: {
              and: [
                getFilter("organiser", "eq", user.userId),
                // getFilter("visibility", "ne", "HIDDEN"),
                {
                  or: [
                    getFilter("startsAt", "gt", getISODateString(new Date(Date.now() - 182 * 24 * 3600 * 1000))),
                    getFilter("distributionType", "eq", "ON-DEMAND"),
                  ],
                },
              ],
            },
          }}
          showItemCount={false}
          showPaginator="never"
          showSettingsControl={false}
          render={(outerProps) => {
            const children = outerProps.rows.map((eventRow) => <EventTile eventRow={eventRow} key={eventRow.id} />);
            return <EventTileContainer>{children}</EventTileContainer>;
          }}
        />
      </Section>
    </InnerFrame>
  );
};
