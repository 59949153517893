import Debug from "debug";
import { Middleware } from "redux";
import { getConfig, makeRow, setFieldValue, TransRow } from "packages/gossamer-react-redux";
import { Event, EventRow } from "model";
import { FieldType, OptionsField } from "packages/gossamer-universal";

const debug = Debug("EventMiddleware");

const CONF_ONLY_FIELDS = [
  "eventScale",
  "allowsOralPresentation",
  "allowsPosterPresentation",
  "clinicalAuditPresentations",
];
const COURSE_TYPES = ["COURSE", "TEACHING-SESSION"];
const COURSE_ONLY_FIELDS = ["teachingProgramme", "teachingQualification"];
const TEACHING_FIELDS = ["teachingProgrammeDuration", "acceptCoTutorOffers"];

const onSetEventType = (entity: typeof Event, row: TransRow<EventRow>, blankFieldOnHide: boolean) => {
  const eventType = row.getFieldValue("type") as string;
  const isCourse = COURSE_TYPES.indexOf(eventType) > -1;
  CONF_ONLY_FIELDS.forEach((fieldId) => {
    entity.fields[fieldId].editable = !isCourse;
    entity.fields[fieldId].mandatory = !isCourse && !!eventType;
    row.setFieldTouched(fieldId);
    if (blankFieldOnHide && isCourse) {
      row.setFieldValue(fieldId, null);
    }
  });
  COURSE_ONLY_FIELDS.forEach((fieldId) => {
    entity.fields[fieldId].editable = isCourse || !eventType;
    entity.fields[fieldId].mandatory = isCourse;
    row.setFieldTouched(fieldId);
    if (blankFieldOnHide && !isCourse && !!eventType) {
      row.setFieldValue(fieldId, entity.fields[fieldId].type === FieldType.Boolean ? false : null);
    }
  });
  onSetTeachingProgramme(entity, row, blankFieldOnHide);
  (entity.fields.distributionType as OptionsField).options.find((opt) => opt.id === "ON-DEMAND").active =
    isCourse || !eventType;
  if (blankFieldOnHide && !isCourse && !!eventType && row.getFieldValue("distributionType") === "ON-DEMAND") {
    row.setFieldValue("distributionType", "");
    onSetDistributionType(entity, row, blankFieldOnHide);
  }
};

const onSetDistributionType = (entity: typeof Event, row: TransRow<EventRow>, blankFieldOnHide: boolean) => {
  const distributionType = row.getFieldValue("distributionType") as string;
  entity.fields.location.editable = ["IN-PERSON", "HYBRID"].indexOf(distributionType) > -1;
  entity.fields.location.mandatory = ["IN-PERSON", "HYBRID"].indexOf(distributionType) > -1;
  entity.fields.startsAt.editable = distributionType !== "ON-DEMAND";
  entity.fields.startsAt.mandatory = distributionType !== "ON-DEMAND" && !!distributionType;
  entity.fields.endsAt.editable = distributionType !== "ON-DEMAND";
  row.setFieldTouched("startsAt");
  row.setFieldTouched("endsAt");
  if (blankFieldOnHide && ["IN-PERSON", "HYBRID"].indexOf(distributionType) === -1) {
    row.setFieldValue("location", null);
  }
  if (blankFieldOnHide && distributionType === "ON-DEMAND") {
    row.setFieldValue("startsAt", "");
    row.setFieldValue("endsAt", "");
  }
};

const onSetBookingMethod = (entity: typeof Event, row: TransRow<EventRow>, blankFieldOnHide: boolean) => {
  const bookingMethod = row.getFieldValue("bookingMethod") as string;
  entity.fields.capacity.editable = bookingMethod === "IN-CAREERBOOK";
  entity.fields.capacity.mandatory = bookingMethod === "IN-CAREERBOOK";
  entity.fields.bookingUrl.editable = bookingMethod === "IN-OTHER-SYSTEM";
  entity.fields.bookingUrl.mandatory = bookingMethod === "IN-OTHER-SYSTEM";
  row.setFieldTouched("capacity");
  row.setFieldTouched("bookingUrl");
  if (blankFieldOnHide && bookingMethod !== "IN-CAREERBOOK") {
    row.setFieldValue("capacity", null);
  }
  if (blankFieldOnHide && bookingMethod !== "IN-OTHER-SYSTEM") {
    row.setFieldValue("bookingUrl", null);
  }
};

const onSetTeachingProgramme = (entity: typeof Event, row: TransRow<EventRow>, blankFieldOnHide: boolean) => {
  const teaching = row.getFieldValue("teachingProgramme");
  console.log("teaching?", teaching);
  TEACHING_FIELDS.forEach((fieldId) => {
    entity.fields[fieldId].editable = teaching !== false;
    entity.fields[fieldId].mandatory = teaching;
    row.setFieldTouched(fieldId);
    if (blankFieldOnHide && !teaching) {
      row.setFieldValue(fieldId, null);
    }
  });
};

export const eventMiddleware: Middleware = (store) => (next) => (action) => {
  // debug(`entering eventMiddleware`);
  const out = next(action);
  try {
    if (action.type === makeRow.type) {
      const entity = action.payload.row.getEntity() as typeof Event;
      if (entity.id === Event.id) {
        const userId = getConfig("user-id");
        // set organiser and organisation
        if (action.payload.isCreating) {
          debug(`setting event organiser to`, userId);
          action.payload.row.setFieldValue("organiser", userId);
          action.payload.row.setFieldValue("organisation", getConfig("org-path"));
        }
        onSetEventType(entity, action.payload.row, action.payload.isCreating);
        onSetDistributionType(entity, action.payload.row, action.payload.isCreating);
        onSetBookingMethod(entity, action.payload.row, action.payload.isCreating);
        onSetTeachingProgramme(entity, action.payload.row, action.payload.isCreating);
      }
    }
    if (action.type === setFieldValue.type) {
      const entity = action.payload.row.getEntity() as typeof Event;
      if (entity.id === Event.id && action.payload.fieldId === "type") {
        onSetEventType(entity, action.payload.row, true);
      }
      if (entity.id === Event.id && action.payload.fieldId === "distributionType") {
        onSetDistributionType(entity, action.payload.row, true);
      }
      if (entity.id === Event.id && action.payload.fieldId === "bookingMethod") {
        onSetBookingMethod(entity, action.payload.row, true);
      }
      if (entity.id === Event.id && action.payload.fieldId === "teachingProgramme") {
        onSetTeachingProgramme(entity, action.payload.row, true);
      }
      action.payload.row.validate();
    }
  } catch (e) {
    console.error(e);
  }
  // debug(`exiting eventMiddleware`);
  return out;
};
