import Debug from "debug";
import { FirebaseApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut as signOutFirebase,
} from "firebase/auth";
import { AuthProvider } from "packages/gossamer-universal";

const debug = Debug("provider-gcp/Auth");

const ERROR_CODES = {
  "auth/email-already-in-use": "there is already an account for this email address",
  "auth/invalid-email": "no (or invalid) credentials supplied",
  "auth/invalid-credential": "invalid email address and/or password",
  "auth/unauthorized-continue-uri": "incorrect set-up",
};

const getErrorMessage = (error: any): string => {
  if (error.code) {
    return ERROR_CODES[error.code] || `unknown code: ${error.code}`;
  }
  return error.message.toString() || error.toString();
};

export const makeFirebaseAuthProvider = (app: FirebaseApp): AuthProvider => {
  const auth = getAuth(app);

  const onload = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("firebase-auth") || "null");
      debug(`authOnLoad start...`, user);
      // if (user) {
      //   debug(`signing in as`, user);
      //   options.onAlreadySignedIn && options.onAlreadySignedIn(user.email, user.displayName);
      // }
      return user.email; // the only useful thing available
    } catch (e) {
      console.error(`error parsing "firebase-auth" value in localStorage`);
    }
  };

  const passwordResetInitiate = async (emailAddress: string) => {
    try {
      await sendPasswordResetEmail(auth, emailAddress, {
        url: "https://careerbook.uk",
      });
    } catch (error) {
      console.error(error);
      throw new Error(getErrorMessage(error));
    }
  };

  const passwordResetComplete = (code: string, newPassword: string) => {
    throw new Error("not implemented");
  };

  const passwordResetResendCode = () => {
    throw new Error("not implemented");
  };

  const signIn = async (emailAddress: string, password: string) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, emailAddress, password);
      const user = userCredential.user;
      localStorage.setItem("firebase-auth", JSON.stringify(user));
      debug(`signIn back`, user);
      return user;
    } catch (error) {
      console.error(error);
      throw new Error(getErrorMessage(error));
    }
  };

  const signInMFACode = (code: string) => {
    throw new Error("not implemented");
  };

  const signOut = async () => {
    localStorage.setItem("firebase-auth", "null");
    await signOutFirebase(auth);
    // options.onSignOut && (await options.onSignOut());
  };

  const signUp = async (emailAddress: string, password: string, displayName?: string) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, emailAddress, password);
      const user = userCredential.user;
      // options.onSuccessfulSignUp && (await options.onSuccessfulSignUp(user.email, displayName || user.displayName));
      // if (options.autoSignInOnSignUp) {
      //   await signIn(emailAddress, password);
      // }
    } catch (error) {
      console.error(error);
      throw new Error(getErrorMessage(error));
    }
  };

  const signUpVerifyCode = (code: string) => {
    throw new Error("not implemented");
  };

  const signUpResendCode = () => {
    throw new Error("not implemented");
  };

  const signUpVerifyMFACode = (code: string) => {
    throw new Error("not implemented");
  };

  return {
    onload,
    passwordResetInitiate,
    passwordResetComplete,
    passwordResetResendCode,
    signIn,
    signInMFACode,
    signOut,
    signUp,
    signUpVerifyCode,
    signUpResendCode,
    signUpVerifyMFACode,
  };
};
