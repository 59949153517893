import Debug from "debug";
import * as React from "react";
import { FormGroup, Table } from "react-bootstrap";
import { Entity, Field, getFieldArray, getFieldColumnAlign, isAllowDeleteRows, Row } from "packages/gossamer-universal";
import { ErrorBoundary, TransField, TransRow } from "../index";

const debug = Debug("gfe/se/Grid");

export interface GridProps<T extends Row> {
  deleteRow: (row: TransRow<T>) => void;
  entity: Entity<T>;
  renderFooter?: () => JSX.Element;
  renderHeader?: (props: GridHeaderProps<T>) => JSX.Element;
  renderRow?: (props: GridRowProps<T>) => JSX.Element;
  rows: TransRow<T>[];
}

export const Grid = <T extends {}>(props: GridProps<T>): JSX.Element => {
  debug("start of render Grid");
  const LocalGridFooter = props.renderFooter || (() => null);
  const LocalGridHeader = props.renderHeader || GridHeader;
  const LocalGridRow = props.renderRow || GridRow;
  const children = props.rows.map((row: TransRow<any>) => {
    return (
      <LocalGridRow key={String(row.getRowNumber())} entity={props.entity} row={row} deleteRow={props.deleteRow} />
    );
  });
  return (
    <ErrorBoundary>
      <Table>
        <LocalGridHeader entity={props.entity} />
        <tbody>{children}</tbody>
        <LocalGridFooter />
      </Table>
    </ErrorBoundary>
  );
};

export interface GridHeaderProps<T extends Row> {
  entity: Entity<T>;
}

export const GridHeader = <T extends {}>(props: GridHeaderProps<T>): JSX.Element => {
  return (
    <thead>
      <tr>
        {isAllowDeleteRows(props.entity) && <th>&nbsp;</th>}
        {getFieldArray(props.entity)
          .filter(([field, _fieldId]: [Field, string]) => field.listColumn)
          .map(([field, fieldId]: [Field, string]) => (
            <th key={fieldId} className={`cellAlign-${getFieldColumnAlign(field)}`}>
              {field.label}
              {field.mandatory ? " *" : ""}
            </th>
          ))}
      </tr>
    </thead>
  );
};

export interface GridRowProps<T extends Row> {
  deleteRow: (row: TransRow<T>) => void;
  entity: Entity<T>;
  row: TransRow<T>;
}

export const GridRow = <T extends {}>(props: GridRowProps<T>): JSX.Element => {
  debug("start of render GridRow");
  return (
    <tr>
      {isAllowDeleteRows(props.entity) && (
        <td>
          <button onClick={() => props.deleteRow(props.row)} className="ControlIcon">
            ✖
          </button>
        </td>
      )}
      {getFieldArray(props.entity)
        .filter(([field, _]: [Field, string]) => field.listColumn)
        .map(([field, fieldId]: [Field, string]) => (
          <td key={fieldId} className={`cellAlign-${getFieldColumnAlign(field)}`}>
            <FormGroup controlId={fieldId}>
              <TransField fieldId={fieldId} field={field} row={props.row} />
            </FormGroup>
          </td>
        ))}
    </tr>
  );
};
